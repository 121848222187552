import Cookie from 'js-cookie'

const state = () => ({
  authenticated: false,
  user: null
})

const getters = {
  authenticated (state) {
    return state.authenticated
  },
  user (state) {
    return state.user
  },
  userRole (state) {
    return state.user.roles.map(({name}) => name)
  },
  isSuperAdmin (state) {
    return state.user.roles.find(role => role.name === 'superadministrator')
  },
  isSupport (state) {
    return state.user.roles.find(role => role.name === 'support')
  },
  userSubscription (state) {
    return state.user.user_subscription
  }
}

const actions = {
  async signIn ({dispatch}, credentials) {
    if (process.env.NODE_ENV === 'development') {
      await axios.post('api/auth/login', credentials)
        .then(({data}) => {
          localStorage.setItem('sanctum_token', data.access_token)
          const loginFrame = document.getElementById('cross-iframe').contentWindow
          loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'set', data: data.access_token}), '*')
          console.log('process.env.VUE_APP_MAIN_COOKIE_DOMAIN :>> ', process.env.VUE_APP_MAIN_COOKIE_DOMAIN)
          Cookie.set('cross_estage_token', data.access_token)
          return dispatch('me')
        })
    }
  },

  async forget (_, payload) {
    return await axios.post('api/auth/password/forget', payload)
  },

  async reset (_, payload) {
    return await axios.post('api/auth/password/reset', {
      ...payload,
      token: new URLSearchParams(window.location.search).get('token')
    })
  },

  async signUp ({dispatch}, credentials) {
    await axios.post('api/auth/signup', credentials)
      .then(async ({data}) => {
        localStorage.setItem('sanctum_token', data)
        const loginFrame = document.getElementById('cross-iframe').contentWindow
        loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'set', data: data}), '*')

        return await dispatch('me')
      })
  },

  async signOut ({commit}) {
    commit('SET_AUTHENTICATED', false)
    commit('SET_USER', null)
    Cookie.remove('cross_estage_token')
    window.location.href = process.env.VUE_APP_AUTH_HOST + '/logout'

    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('sanctum_token', null)
    }

    // await axios.get('api/auth/logout')
    //   .then(() => {
    //     localStorage.removeItem('sanctum_token')
    //     const loginFrame = document.getElementById('cross-iframe').contentWindow
    //     loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'remove'}), '*')

    //     commit('SET_AUTHENTICATED', false)
    //     commit('SET_USER', null)

    //     router.push('/login')
    //   }).finally(()=>{
    //     Cookie.remove('cross_estage_token',{ domain: process.env.VUE_APP_MAIN_COOKIE_DOMAIN })
    //   })
  },

  async me ({ rootGetters, commit }) {
    // Helper functions for cleaner conditions
    const isDevelopment = process.env.NODE_ENV === 'development'
    const getToken = () => isDevelopment
      ? localStorage.getItem('sanctum_token')
      : Cookie.get('cross_estage_token')

    const redirectTo = (path) => {
      window.open(path, '_self')
    }

    const isCurrentPage = (page) => {
      const currentPage = window.location.pathname.replace(/\/$/, '')
      return page instanceof RegExp ? page.test(currentPage) : currentPage === page
    }

    if (!getToken()) return false

    try {
      const { data } = await axios.get('api/auth/user')
      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', data)

      const userRole = rootGetters['auth/userRole']
      const isSubscriptionInvalid =
          !data.user_subscription ||
          !['succeeded', 'succeeeded'].includes(data.user_subscription.status)

      const shouldRedirectToSignup =
          !data.user_subscription &&
          userRole.includes('user_project') &&
          !(isCurrentPage('/signup') || isCurrentPage(/\/account/))

      const shouldRedirectToReactivate =
          data.user_subscription &&
          isSubscriptionInvalid &&
          !(isCurrentPage('/reactivate') || isCurrentPage(/\/account/))

      if (shouldRedirectToSignup || (data.projects_count === 0 && shouldRedirectToSignup)) {
        redirectTo('/signup')
      } else if (shouldRedirectToReactivate) {
        redirectTo('/reactivate')
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
      localStorage.removeItem('sanctum_token')
      const loginFrame = document.getElementById('cross-iframe')?.contentWindow
      if (loginFrame) {
        loginFrame.postMessage(JSON.stringify({ key: 'sanctum_token', method: 'remove' }), '*')
      }
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
    }
  }
}

const mutations = {
  SET_AUTHENTICATED (state, paylaod) {
    state.authenticated = paylaod
  },

  SET_USER (state, payload) {
    state.user = payload
  },

  UPDATE_EMAIL (state, payload) {
    state.user.email = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
