<template>
  <button
    :disabled="loading"
    class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
    @click="addItem"
  >
    Add List Item
    <span class="text-[#3D82EA] text-[20px]">+</span>
  </button>
</template>

<script>
import shortid from 'shortid'
export default {
  name: 'AddNewControl',
  props: {
    itemsCount: {
      type: Number,
      required: true
    },
    formId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    addItem () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      this.loading = true
      this.$emit('added-item', {
        uid: uid,
        name: 'Field Input',
        css: null,
        sid: sid,
        hideMod: true,
        hideRight: true,
        component: 'ElFieldInput',
        fieldSettings: true,
        options: {
          content: {
            control: {
              label: `Item #${this.itemsCount + 1}`,
              placeholder: '',
              field_name: `Item #${this.itemsCount + 1}`,
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            }
          },
          customize: null
        }
      })
      this.$emit('added-control', {
        uid: uid,
        sid: sid,
        field_name: `Item #${this.itemsCount + 1}`,
        field_type: 'text',
        values: null,
        validators: '[]',
        is_required: true,
        mapping: []
      })
      this.loading = false
    }
  }
}
</script>