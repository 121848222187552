import router from '@builder/router/index'
import Vue from 'vue'
import {applyPageStyles, pageStylesToStringAsync} from '@/utils/useStylesheet'
import {toStringify} from '@/utils/helpers'
// const CleanCSS = require('clean-css')

var _ = require('lodash')

const state = () => ({
  project: null,
  projects: [],
  businessDetails: {
    logo: '',
    name: '',
    address: '',
    country: '',
    city: '',
    state: '',
    zip_code: '',
    support_email: '',
    support_phone: '',
    support_phone_code: {
      name: 'United States',
      dial_code: '+1',
      code: 'US'
    }
  },
  loaded: false,
  publishing: false,
  newProjectId: null,
  css: null,
  pages:{},
  posts:{},
  autoResponderTags:{}
})

const getters = {}

const actions = {
  async preparePublishing ({rootState, dispatch}) {
    const navbarFonts = await dispatch('font/getFontsFromJson', rootState.editor.globalStyles.navbar, {root: true})

    return await new Promise((resolve) => {
      let globalStyleTextFonts = _.reduce(rootState.editor.globalStyles.textStyles, function (result, value) {
        result = [...(Array.isArray(result) ? result : []), ..._.flatMap(value.fontFamily)]
        return result
      }, {})

      return dispatch('font/prepareGlobalFonts', {}, {root: true})
        .then(async (fonts) => {
          await dispatch('font/fontsArrayToString', [
            ...globalStyleTextFonts,
            ...navbarFonts,
            ...[_.get(rootState.editor.globalStyles, ['button', 'options', 'content', 'button', 'fontFamily'], '')]
          ], {root: true})
            .then(async (data) => {
              const pFonts  = await dispatch('font/prepareFonts', {}, {root: true})
              const googleFonts = await dispatch('font/fetchGoogleFontsCSS', data.googleFonts, {root: true})
              Vue.set(rootState.editor.globalStyles.font, 'googleFonts', googleFonts + fonts.googleFonts + pFonts.googleFonts)
            })

          dispatch('rawStyles')

          return resolve(true)
        })
    })
  },
  async rawStyles ({rootState}) {
    return await new Promise((resolve) => {
      const getTextStyles = (type, resolution) => {
        return `{font-size: ${type !== 'link' && type !== 'link-hover' ? rootState.editor.globalStyles.textStyles[type].fontSize[resolution] : ''};
           font-family: ${rootState.editor.globalStyles.textStyles[type].fontFamily[resolution]};
           font-weight: ${rootState.editor.globalStyles.textStyles[type].fontWeight[resolution]};
           color: ${rootState.editor.globalStyles.textStyles[type].color.lg};
           text-align: ${rootState.editor.globalStyles.textStyles[type].align[resolution]};
           line-height: ${type !== 'link' && type !== 'link-hover' ? rootState.editor.globalStyles.textStyles[type].lineHeight[resolution] : ''};
           letter-spacing: ${type !== 'link' && type !== 'link-hover' ? rootState.editor.globalStyles.textStyles[type].letterSpacing[resolution] : ''}px;
           text-decoration: ${rootState.editor.globalStyles.textStyles[type].textDecoration[resolution] || 'unset'};
           font-style: ${rootState.editor.globalStyles.textStyles[type].textStyle[resolution] || 'unset'};}
        `
      }

      const getFontStyles = (type, resolution) => {
        return `{font-size: ${type !== 'link' && type !== 'link-hover' ? rootState.editor.globalStyles.textStyles[type].fontSize[resolution] : ''};
           font-family: ${rootState.editor.globalStyles.textStyles[type].fontFamily[resolution]};
           font-weight: ${rootState.editor.globalStyles.textStyles[type].fontWeight[resolution]};         
        }`
      }

      Vue.set(rootState.editor.globalStyles, 'rawStyles', `
          h1 .headline ${getTextStyles('h1', 'sm')}
          h2 .headline ${getTextStyles('h2', 'sm')}
          h3 .headline ${getTextStyles('h3', 'sm')}
          h4 .headline ${rootState.editor.globalStyles.textStyles.h4 ? getTextStyles('h4', 'sm') : getTextStyles('h3', 'sm')}
          h5 .headline ${rootState.editor.globalStyles.textStyles.h5 ? getTextStyles('h5', 'sm') : ''}
          h6 .headline ${rootState.editor.globalStyles.textStyles.h6 ? getTextStyles('h6', 'sm') : ''}
         .paragraph ${getTextStyles('paragraph', 'sm')}
         .editor__content a:not([fromglobalstyle=false]) ${getTextStyles('link', 'sm')}
         .editor__content a:not([fromglobalstyle=false]):hover ${getTextStyles('link-hover', 'sm')}   
         .el-menu-link ${getFontStyles('link', 'sm')}    
          
          @media (min-width: 520px) {
            h1 .headline ${getTextStyles('h1', 'md')}
            h2 .headline ${getTextStyles('h2', 'md')}
            h3 .headline ${getTextStyles('h3', 'md')}
            h4 .headline ${rootState.editor.globalStyles.textStyles.h4 ? getTextStyles('h4', 'md') : getTextStyles('h3', 'md')}
            h5 .headline ${rootState.editor.globalStyles.textStyles.h5 ? getTextStyles('h5', 'md') : ''}
            h6 .headline ${rootState.editor.globalStyles.textStyles.h6 ? getTextStyles('h6', 'md') : ''}
           .paragraph ${getTextStyles('paragraph', 'md')}
           .editor__content a:not([fromglobalstyle=false]) ${getTextStyles('link', 'md')}
           .editor__content a:not([fromglobalstyle=false]):hover ${getTextStyles('link-hover', 'md')}
           .el-menu-link ${getFontStyles('link', 'sm')}
          }
          
          @media (min-width: 1100px) {
            h1 .headline ${getTextStyles('h1', 'lg')}
            h2 .headline ${getTextStyles('h2', 'lg')}
            h3 .headline ${getTextStyles('h3', 'lg')}
            h4 .headline ${rootState.editor.globalStyles.textStyles.h4 ? getTextStyles('h4', 'lg') : getTextStyles('h3', 'lg')}
            h5 .headline ${rootState.editor.globalStyles.textStyles.h5 ? getTextStyles('h5', 'lg') : ''}
            h6 .headline ${rootState.editor.globalStyles.textStyles.h6 ? getTextStyles('h6', 'lg') : ''}
           .paragraph ${getTextStyles('paragraph', 'lg')}
           .editor__content a:not([fromglobalstyle=false]) ${getTextStyles('link', 'lg')}
           .editor__content a:not([fromglobalstyle=false]):hover ${getTextStyles('link-hover', 'lg')}
           .el-menu-link ${getFontStyles('link', 'sm')}
          }
      `)

      return resolve(true)
    })
  },
  async create (_, payload) {
    applyPageStyles('@media screen {} @media (max-width: 1100px) {} @media (max-width: 520px) {}')
    return await axios.post('api/projects', payload)
  },

  async gettingReady ({rootState, state, dispatch}, data) {
    VEvent.fire('loader.message', 'Creating new project...')
    state.project = data.data
    rootState.editor.globalStyles = JSON.parse(data.data.global_settings)

    if (data.data.template_id !== 1) return dispatch('prepareTemplate', data)

    VEvent.fire('loader.message', 'Creating home page...')

    dispatch('pages/addPage', {
      title: 'Home',
      meta_title: 'Home',
      is_home: 1,
      content: '[]',
      content_draft: '[]',
      dont_dispatch: true
    }, {root: true})
      .then((data) => {
        rootState.pages.page = data.data
        VEvent.fire('loader.message', 'Project preparation...')

        const content = JSON.parse(data.content)
        rootState.sections.sections = content.content || []
        return dispatch('menu/createMenu', 'navbar', {root: true})
      })
      .then(({data}) => {
        rootState.editor.globalStyles.navbar = {
          menu_id: data.data.id
        }
        return dispatch('menu/createMenu', 'footer', {root: true})
      })
      .then(() => {
        rootState.editor.globalStyles.footer = {
          menu_id: data.data.id
        }
        return dispatch('save')
      })
      .then(async () => {
        await dispatch('pages/loadHome', '', {root: true})
        await router.push({name: 'editor', params: {id: state.project.id}})
        dispatch('load')
      })
  },
  async clone ({rootState, state, dispatch}, payload) {
    VEvent.fire('loader.message', 'Cloning the project')
    let pages = []
    const clearContent = (content) => {
      let contentDraft = content
      if (!content) {
        return '[]'
      }

      contentDraft = contentDraft.replace(/"id":\d+,?/g, '')
        .replace(/"form_id":\d+,?/g, '')

      contentDraft = contentDraft.replace('"action":"Modal"', '$modalAction$')
      contentDraft = contentDraft.replace(/\{"modal_id":(.*?)\\}/g, '{"modal_id":"{modal-$1}"}')

      if (!payload.safeMode) {
        contentDraft = contentDraft.replace(/"action":".*?",?/g, '')
          .replace(/"customLink":".*?",?/g, '')
      }

      contentDraft = contentDraft.replace(/"actions":\[.*?\](,?)/g, '"actions": []$1')
        .replace(/"action":\{.*?\}(,?)/g, '"action": null$1')

      contentDraft = contentDraft.replace('$modalAction$', '"action":"Modal"')

      return contentDraft.replace(/,\}/g, '}')
    }

    payload.data.data.page.forEach(page => {
      pages.push({
        slug: page.slug,
        title: page.title,
        is_home: page.is_home,
        meta_title: page.meta_title,
        page_status: page.page_status,
        content_draft: clearContent(page.content_draft),
        content: clearContent(page.content),
        code_injection: page.code_injection,
        settings: page.settings,
        css_hash: page.css_hash,
        fonts: page.fonts
      })
    })
    VEvent.fire('loader.message', 'Preparing')

    const setup = {
      original_id: payload.data.data.id,
      pages,
      navs: payload.data.data.nav.length
        ? JSON.parse(JSON.stringify(payload.data.data.nav[0].items)
          .replace(/"id":\d+,?/g, '')
          .replace(/"item_object_id":\d+,?/g, '')
          .replace(/"menu_id":\d+,?/g, ''))
        : []
    }

    VEvent.fire('loader.message', 'Creating')

    // console.log({
    //   setup: setup,
    //   globalStyle: JSON.parse(payload.data.data.global_settings)
    // })
    // return

    // eslint-disable-next-line no-unreachable
    return await axios.post('api/projects', {
      name: `imported-${payload.data.data.project_name}(${new Date().getTime()})`,
      subdomain: `imported-${new Date().getTime()}`,
      favicon: '',
      setup: JSON.stringify(setup),
      template_id: payload.data.data.template_id,
      global_settings: payload.data.data.global_settings,
      screenshot: payload.data.data.screenshot,
      custom_fonts: payload.data.data.custom_fonts,
      css: payload.css
    })
      .then(({data}) => {
        return axios.get(`api/projects/${data.data.id}`)
          .then(({data}) => {
            state.project = data.data
            rootState.editor.globalStyles = JSON.parse(data.data.global_settings)
            return dispatch('prepareTemplate', data)
          })
          .catch(() => {
            VEvent.fire('loader.message', 'Something went wrong')
            setTimeout(() => {
              return window.location.href = '/'
            }, 5000)
          })
      })
  },
  prepareTemplate ({state, rootState, dispatch}, data) {
    VEvent.fire('loader.message', 'Template preparation...')

    const content = JSON.parse(data.data.content)
    rootState.sections.sections = content.content || []
    dispatch('menu/getNavbarTemplateMenu', null, {root: true})
      .then(({data}) => {
        rootState.editor.globalStyles.navbar.menu_id = data.data.id
        return dispatch('saveProject')
      })
      .then(() => {
        router.push({name: 'editor', params: {id: state.project.id}})
        dispatch('load')
      })
  },
  projects ({state}) {
    VEvent.fire('loader.message', 'Loading all projects...')

    axios.get('api/projects')
      .then(({data}) => {
        state.projects = data.data.data
      })
  },
  async load ({state, rootState, dispatch}) {
    if (!router.currentRoute.params.id) return false
    VEvent.fire('loader.message', 'Project preparation...')

    state.businessDetails = {
      logo: '',
      name: '',
      address: '',
      country: '',
      city: '',
      state: '',
      zip_code: '',
      support_email: '',
      support_phone: '',
      support_phone_code: {
        name: 'United States',
        dial_code: '+1',
        code: 'US'
      }
    }

    return await axios.get(`api/projects/${router.currentRoute.params.id}`)
      .then(({data}) => {
        state.project = data.data
        state.businessDetails = {
          logo: data.data.business_info?.logo,
          name: data.data.business_info?.name,
          address: data.data.business_info?.address,
          country: data.data.business_info?.country,
          city: data.data.business_info?.city,
          state: data.data.business_info?.state,
          zip_code: data.data.business_info?.zip_code,
          support_email: data.data.business_info?.support_email,
          support_phone: data.data.business_info?.support_phone,
          support_phone_code: data.data.business_info?.support_phone_code || {name: 'United States', dial_code: '+1', code: 'US'}
        }
        data.data.draft_css !== null ? applyPageStyles(data.data.draft_css) : applyPageStyles(data.data.css)
        rootState.editor.globalStyles = JSON.parse(state.project.global_settings)

        if (!rootState.editor.globalStyles.font.addedGoogleFonts) {
          Vue.set(rootState.editor.globalStyles.font, 'addedGoogleFonts', [
            {
              'family': 'Roboto',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Open Sans',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Noto Sans JP',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Montserrat',
              'variants': [
                '300',
                '400',
                '700',
                '800'
              ]
            },
            {
              'family': 'Lato',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Poppins',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Source Sans Pro',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Roboto Condensed',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Oswald',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Roboto Mono',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Raleway',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Noto Sans',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Inter',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Mukta',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Ubuntu',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Roboto Slab',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Nunito',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Merriweather',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'PT Sans',
              'variants': [
                '400',
                '700'
              ]
            },
            {
              'family': 'Playfair Display',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Rubik',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Nunito Sans',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Work Sans',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Lora',
              'variants': [
                '400',
                '700'
              ]
            },
            {
              'family': 'Nanum Gothic',
              'variants': [
                '400',
                '700'
              ]
            },
            {
              'family': 'Noto Serif',
              'variants': [
                '400',
                '700'
              ]
            },
            {
              'family': 'Fira Sans',
              'variants': [
                '300',
                '400',
                '700'
              ]
            },
            {
              'family': 'Barlow',
              'variants': [
                '300',
                '400',
                '700'
              ]
            }
          ])
        }

        if (!rootState.editor.globalStyles.font.installedFonts) {
          Vue.set(rootState.editor.globalStyles.font, 'installedFonts', [])
        }

        rootState.sections.navbar = rootState.editor.globalStyles.navbar.section
      })
      .then(() => {
        return dispatch('pages/loadPages', '', {root: true})
      })
      .then(() => {
        state.loaded = true
        dispatch('modal/fetch', '', {root: true})
        dispatch('font/fetchProjectFonts', '', {root: true})
      })
  },
  async delete ({state}, payload) {
    return await axios.delete(`api/projects/${payload.id}`)
      .then(() => {
        state.projects.splice(payload.index, 1)
      })
  },
  async saveProject ({state, rootState}) {
    state.project.global_settings = JSON.stringify(rootState.editor.globalStyles)
    return await axios.put(`api/projects/${state.project.id}`, {...state.project})
  },
  async save ({state, rootState, dispatch, rootGetters}, force = false) {
    if (!force && !rootGetters['sections/getSections'].length) return

    state.publishing = true
    state.project.global_settings = JSON.stringify(rootState.editor.globalStyles)

    return pageStylesToStringAsync()
      .then(async (css) => {
        return await axios.put(`api/projects/${state.project.id}/css`, {
          css: state.project.css !== null ? state.project.css : css,
          draft_css: css
        })
          .then(async () => {
            return await axios.put(`api/projects/${state.project.id}`, {
              ...state.project,
              css: '',
              draft_css: '',
              setup: '',
              variables: typeof state.project.variables ===  'string' ? state.project.variables : toStringify(state.project.variables)
            })
              .then(() => {
                state.publishing = false
                return dispatch('pages/save', {}, {root: true})
              })
              .catch(() => {
                this.$swal({
                  icon: 'error',
                  title: 'Server error',
                  text: 'Please try again later or let us know about your problem.',
                  showConfirmButton: false,
                  timer: 3500
                })
              })
          })
      })
  },
  publish ({state, rootGetters, rootState, dispatch}, payload) {
    if (!rootGetters['sections/getSections'].length) return

    state.publishing = true
    dispatch('preparePublishing')
      .then(() => {
        state.project.global_settings = JSON.stringify(rootState.editor.globalStyles)
        pageStylesToStringAsync()
          .then(async (css) => {
            return await axios.put(`api/projects/${state.project.id}/css`, {
              css,
              draft_css: css
            })
              .then(() => {
                state.project.css = css
                axios.put(`api/projects/${state.project.id}`, state.project)
                  .then(() => {
                    setTimeout(async () => {
                      dispatch('pages/save', {clearCache: 1, all: payload.all}, {root: true})

                      const publish = await axios.put(`api/projects/${state.project.id}/pages/status`, {
                        pages: payload.pages
                      })

                      state.publishing = false
                      payload.callback()

                      return publish
                    }, 100)
                  })
              })
          })
      })
  },
  getPages ({state  }, payload) {
    axios.get(`api/projects/${payload.project_id}/pages?all_page=1`)
      .then((data) => {
        state.pages = {
          project_id:payload.project_id,
          data:data.data.data
        }
      }).catch((err) => {
        console.error(err)
      })
  },
  getPosts ({state  }, payload) {
    axios.get(`https://blog-api.estage.com/api/projects/${payload.project_id}/blog/manage/posts?all_posts=1`)
      .then((data) => {
        state.posts = {
          project_id:payload.project_id,
          data:data.data.data
        }
      }).catch((err) => {
        console.error(err)
      })
  },
  async getAutoResponderTags ({state  }, payload) {
    await axios.get(`api/projects/${payload.project_id}/auto-responders/${payload.alpha_code}/custom-tags`)
      .then((data) => {
        if (!state.autoResponderTags[payload.project_id]) {
          state.autoResponderTags[payload.project_id] = {}
        }
        state.autoResponderTags[payload.project_id][payload.alpha_code] = data.data.data
      }).catch((err) => {
        console.error(err)
      })
  },
  async createAutoResponderTags (_, payload) {
    try {
      const response = await axios.post(
        `api/projects/${payload.project_id}/auto-responders/${payload.alpha_code}/custom-tags`,
        { name: payload.name }
      )
      return response.data.data
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

const mutations = {
  RESET (state) {
    state.project = null
  },
  SET_PUBLISING (state, payload) {
    state.publishing = payload
  },
  SET_NEW_PROJECT_ID (state, payload) {
    state.newProjectId = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}