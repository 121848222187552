import _ from 'lodash'

const state = () => ({
  selectedProvider: null,
  providers: [],
  products: []
})

const actions = {
  setProvider ({ commit }, provider) {
    commit('SET_PROVIDER', provider)
  },
  setProvidersList ({ commit }, list) {
    commit('SET_PROVIDERS_LIST', list)
  },
  addProvidersList ({ commit }, item) {
    commit('ADD_PROVIDERS_LIST', item)
  },
  removeProvidersList ({ commit }, item) {
    commit('REMOVE_PROVIDERS_LIST', item)
  }
}

const mutations = {
  SET_PROVIDER (state, provider) {
    state.selectedProvider = provider
  },
  SET_PROVIDERS_LIST (state, list) {
    state.providers = list
  },
  ADD_PROVIDERS_LIST (state, item) {
    state.providers.push(item)
  },
  REMOVE_PROVIDERS_LIST (state, item) {
    const index = _.findIndex(state.providers, item)
    state.providers.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
