<template>
  <b-form
    class="w-full flex el-form-wrapper"
    @submit.prevent="submit"
  >
    <elements
      v-if="!confirmation"
      v-model="step.content"
      :product="product"
      :classes="`--${section.sid}--form flex flex-col`"
      class="h-100"
      :config="section.options.content.orderForm"
      drag-height="150px"
      :gdpr="gdpr"
      @flow="handleFlow"
      @click.stop.native="$store.commit('editor/SET_PAGE', 'add-element')"
    />
    <elements
      v-else
      v-model="section.options.content.orderForm.confirmation[0].content"
      :product="product"
      :classes="`--${section.sid}--form flex flex-col`"
      class="h-100"
      :config="section.options.content.orderForm"
      drag-height="150px"
      @flow="handleFlow"
      @click.stop.native="$store.commit('editor/SET_PAGE', 'add-element')"
    />
  </b-form>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Elements from '@/components/builder/utils/Elements/Elements.vue'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'OrderFormLayout',
  mixins: [SectionMixin],
  components: {
    Elements
  },
  props: {
    step: {
      type: Object,
      required: true
    },
    stepsCount: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    confirmation: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      gdprCheck: false,
      loading: false
    }
  },
  computed: {
    ...mapState('products', {
      selectedProduct: state => state.selectedProduct,
      products: state => state.products
    }),
    product () {
      return this.section.options.content.orderForm.product
    },
    gdpr () {
      if ((this.stepsCount - 1) === this.currentStep) {
        return this.selectedProduct?.gdpr || ''
      }
      return ''
    }
  },
  methods: {
    ...mapActions({
      setProvidersList: 'orderForm/setProvidersList'
    }),
    submit () {
      console.log('submit')
    }
  },
  watch: {
    selectedProduct (val) {
      if (val?.available_payments) {
        this.setProvidersList(val.available_payments.map((item) => {
          return {
            name: item.project_to_integration.integration.name,
            code: item.project_to_integration.integration.alpha_code
          }
        }))
      }
    }
  },
  mounted () {
    if (this.selectedProduct?.available_payments) {
      this.setProvidersList(this.selectedProduct.available_payments.map((item) => {
        return {
          name: item.project_to_integration.integration.name,
          code: item.project_to_integration.integration.alpha_code
        }
      }))
    }
  }
}
</script>