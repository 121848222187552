<template>
  <div>
    <div
      class="flex items-center btn-group w-full"
      :class="{'flex-wrap': stack}"
    >
      <button
        v-for="step in stepsCount"
        :key="step"
        class="btn h-[33px] !text-[13px] border hover:!bg-[#F2F4F5] !text-[#44474A] px-3 py-0 mb-[16px]"
        :class="[{'!bg-[#F2F4F5]': currentStep === step}, {'w-100 mb-2': stack}]"
        @click="setStep(step)"
        v-text="step + 1"
      />
    </div>
    
    <button 
      v-if="showConfirmation"
      class="w-full btn h-[33px] px-[12px] mb-[16px] text-[13px] border hover:bg-[#F2F4F5]"
      :class="[{'!bg-[#F2F4F5]': confirmation}]"
      @click="setConfirmation"
    >
      Confirmation
    </button>

    <div
      v-for="step in stepsCount"
      v-if="currentStep === step"
      :key="`slot-${step}`"
    >
      <slot :name="step" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'WizardRuleTabs',
  props: {
    steps: {
      type: Number,
      required: true
    },
    active: {
      type: Number,
      default: 0
    },
    stack: {
      default: false
    },
    showConfirmation: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapState('forms', {
      count: state => state.wizard.count,
      currentStep: state => state.wizard.currentStep,
      confirmation: state => state.wizard.confirmation
    }),
    stepsCount () {
      const list = []
      for (let i = 0; this.count > i; i++) list.push(i)
      return list
    }
  },
  methods: {
    ...mapActions('forms', ['setWizardConfirmation', 'setWizardCurrentStep']),
    setStep (step) {
      this.setWizardConfirmation(false)
      this.setWizardCurrentStep(step)
      this.$emit('change', step)
    },
    setConfirmation () {
      this.setWizardConfirmation(true)
      this.$emit('change', null)
    }
  }
}
</script>