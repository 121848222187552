<template>
  <div>
    <SidbearSettingsGroup title="Products">
      <select-product
        v-model="section.options.content.orderForm.products"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      active
      title="Form Fields"
    >
      <b-form-group label="How many steps?">
        <v-select
          :value="count"
          :options="[1, 2, 3, 4]"
          :style="{ width: '76px', height: '38px !important' }"
          @input="updateWizardCount"
        />
      </b-form-group>

      <b-form-group
        v-if="!confirmation"
        label="Step Title"
      >
        <b-input
          v-model="section.options.content.orderForm.steps[currentStep].options.name"
          :style="{ height: '38px !important' }"
        />
      </b-form-group>

      <wizard-rule-tabs
        :steps="count"
        :active="currentStep"
        :show-confirmation="section.options.content.orderForm.confirmation[0].options.show"
        @active="setWizardCurrentStep($event)"
      >
        <template
          v-for="(step, index) in section.options.content.orderForm.steps"
          :slot="index"
        >
          <div :key="index">
            <b-form-group class="mb-[20px]">
              <b-checkbox v-model="section.options.content.orderForm.confirmation[0].options.show">
                Show Confirmation Step
              </b-checkbox>
            </b-form-group>

            <form-field-v3
              :current-step="currentStep"
              :confirmation-step="confirmation"
              :section="section"
            />
          </div>
        </template>
      </wizard-rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Form Button Actions">
      <wizard-rule-tabs
        :steps="count"
        :active="currentStep"
        :show-confirmation="false"
        @active="setWizardCurrentStep($event)"
      >
        <template
          v-for="(step, index) in section.options.content.orderForm.steps"
          :slot="index"
        >
          <div :key="index">
            <actions-after-submit
              v-model="section.options.content.orderForm.steps[index].form.actions"
              :only-responder="true"
            />

            <div>
              <div
                v-for="(action, actionIndex) in section.options.content.orderForm.steps[index].form.actions"
                :key="actionIndex"
              >
                <div
                  :class="{'bg-[#F9F9F9]': activeAction === actionIndex + 4}"
                  class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[60px] px-[23px] font-[700] text-[#44474A]"
                  @click="activeAction === actionIndex + 4 ? activeAction = null : activeAction = actionIndex + 4"
                >
                  {{ action.name }}

                  <svg
                    :class="{'transform rotate-180': activeAction === actionIndex + 4}"
                    fill="none"
                    height="7"
                    viewBox="0 0 10 7"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                      stroke="#6E747A"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>

                <div
                  v-if="activeAction === actionIndex + 4"
                  class="pt-[16px]"
                >
                  <component
                    :is="camelCase(action.name)"
                    :config="section.options.content.orderForm.steps[index]"
                    :controls="section.options.content.orderForm.steps[index].form.controls"
                    :index="activeAction"
                    :service="action"
                    class="pb-3"
                    @removeAction="removeAction(index, actionIndex)"
                    @deleteResponder="(service) => deleteResponder(index, service)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </wizard-rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup 
      title="Steps"
      group
    >
      <v-select
        v-model="section.options.content.orderForm.stepType"
        :options="stepTypes"
        :reduce="item => item.value"
        lang="label"
        class="custom-select mb-[16px]"
        :style="{ height: '38px !important' }"
        placeholder="Select Step Type"
      />
      
      <rule-tabs 
        v-if="section.options.content.orderForm.stepType === 'tabs'" 
        :tabs="['Active', 'Inactive', 'Hover']"
      >
        <template #Inactive>
          <sid-font :sid="`.--${section.sid}--step`" />

          <sid-range-slider
            label="Font Size"
            rule-name="font-size"
            :sid="`.--${section.sid}--step`"
          />

          <sid-range-slider
            label="Letter Spacing"
            rule-name="letter-spacing"
            :sid="`.--${section.sid}--step`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Font Color</label>

              <sid-color
                rule-name="color"
                :sid="`.--${section.sid}--step`"
              />
            </div>
          </b-form-group>

          <sid-align
            label="Text Alignment"
            rule-name="text-align"
            :sid="`.--${section.sid}--step .step-title`"
          />
                  
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>
                      
              <sid-font-style
                rule-name="font" 
                :sid="`.--${section.sid}--step .step-title`" 
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--step`"
            default-color="#ededed"
          />

          <sid-flex-direction
            :sid="`.--${section.sid}--header .form-wizard-steps`"
            rule-name="flex-direction"
          />
        </template>
        <template #Active>
          <sid-font :sid="`.--${section.sid}--step.active`" />

          <sid-range-slider
            label="Font Size"
            rule-name="font-size"
            :sid="`.--${section.sid}--step.active`"
          />

          <sid-range-slider
            label="Letter Spacing"
            rule-name="letter-spacing"
            :sid="`.--${section.sid}--step.active`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Font Color</label>

              <sid-color
                rule-name="color"
                :sid="`.--${section.sid}--step.active`"
              />
            </div>
          </b-form-group>

          <sid-align
            label="Text Alignment"
            rule-name="text-align"
            :sid="`.--${section.sid}--step.active .step-title`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                rule-name="font"
                :sid="`.--${section.sid}--step.active .step-title`"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--step.active`"
            default-color="#ffffff"
          />
        </template>
        <template #Hover>
          <sid-font
            :sid="`.--${section.sid}--step`"
            presudo=":hover"
          />

          <sid-range-slider
            label="Font Size"
            rule-name="font-size"
            :sid="`.--${section.sid}--step`"
            presudo=":hover"
          />

          <sid-range-slider
            label="Letter Spacing"
            rule-name="letter-spacing"
            :sid="`.--${section.sid}--step`"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Font Color</label>

              <sid-color
                rule-name="color"
                :sid="`.--${section.sid}--step`"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-align
            label="Text Alignment"
            rule-name="text-align"
            :sid="`.--${section.sid}--step .step-title`"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                rule-name="font"
                :sid="`.--${section.sid}--step .step-title`"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--step`"
            presudo=":hover"
            default-color="#ffffff"
          />
        </template>
      </rule-tabs>

      <template v-if="section.options.content.orderForm.stepType === 'progress'">
        <SidbearSettingsGroup title="Progress Counter">
          <sid-font
            :sid="`.--${section.sid}--progress .progress-counter`"
          />

          <sid-range-slider
            label="Font Size"
            rule-name="font-size"
            :sid="`.--${section.sid}--progress .progress-counter`"
          />

          <sid-range-slider
            label="Letter Spacing"
            rule-name="letter-spacing"
            :sid="`.--${section.sid}--progress .progress-counter`"
          />

          <sid-range-slider
            label="Line Height"
            rule-name="line-height"
            :sid="`.--${section.sid}--progress .progress-counter`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Font Color</label>

              <sid-color
                rule-name="color"
                :sid="`.--${section.sid}--progress .progress-counter`"
              />
            </div>
          </b-form-group>

          <sid-align
            label="Text Alignment"
            rule-name="text-align"
            :sid="`.--${section.sid}--progress .progress-counter`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                rule-name="font"
                :sid="`.--${section.sid}--progress .progress-counter`"
              />
            </div>
          </b-form-group>
        </SidbearSettingsGroup>
        
        <SidbearSettingsGroup title="Progress Title">
          <sid-font
            :sid="`.--${section.sid}--progress .progress-title`"
          />

          <sid-range-slider
            label="Font Size"
            rule-name="font-size"
            :sid="`.--${section.sid}--progress .progress-title`"
          />

          <sid-range-slider
            label="Letter Spacing"
            rule-name="letter-spacing"
            :sid="`.--${section.sid}--progress .progress-title`"
          />
          
          <sid-range-slider
            label="Line Height"
            rule-name="line-height"
            :sid="`.--${section.sid}--progress .progress-title`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Font Color</label>

              <sid-color
                rule-name="color"
                :sid="`.--${section.sid}--progress .progress-title`"
              />
            </div>
          </b-form-group>

          <sid-align
            label="Text Alignment"
            rule-name="text-align"
            :sid="`.--${section.sid}--progress .progress-title`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                rule-name="font"
                :sid="`.--${section.sid}--progress .progress-title`"
              />
            </div>
          </b-form-group>
        </SidbearSettingsGroup>
        
        <SidbearSettingsGroup title="Progress Bar">
          <sid-background
            label="Background Color"
            :sid="`.--${section.sid}--progress .progress`"
            default-color="#E7EDF8"
          />

          <sid-background
            label="Active Color"
            :sid="`.--${section.sid}--progress .progress-bar`"
            default-color="#E7EDF8"
          />

          <sid-range-slider
            label="Width"
            :sid="`.--${section.sid}--progress .progress`"
            rule-name="width"
            var="--width"
            default-dimension="%"
            :dimensions="['px', '%']"
            measurement="width"
          />

          <sid-range-slider
            label="Height"
            :sid="`.--${section.sid}--progress .progress`"
            rule-name="height"
            var="--height"
            default-dimension="px"
            :dimensions="[]"
          />

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--progress .progress`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </SidbearSettingsGroup>
        
        <SidbearSettingsGroup title="Background">
          <sid-background
            :sid="`.--${section.sid}--progress`"
            default-color="#ffffff"
          />
        </SidbearSettingsGroup>
      </template>
      
      <SidbearSettingsGroup
        v-if="section.options.content.orderForm.stepType !== 'hide'"
        title="Step Border"
      >
        <rule-tabs :tabs="['Active', 'Inactive', 'Hover']">
          <template #Inactive>
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="`.--${section.sid}--step`"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  :options="[
                    {
                      label: 'Solid',
                      value: 'solid'
                    }, 
                    {
                      label: 'Dashed', 
                      value: 'dashed'
                    }, 
                    {
                      label: 'Dotted', 
                      value: 'dotted'
                    }, 
                    {
                      label: 'Double', 
                      value: 'double'
                    }, 
                    {
                      label: 'Outset', 
                      value: 'outset'
                    }
                  ]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="`.--${section.sid}--step`"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                :angles="[
                  {
                    text: '',
                    value: 'top-width',
                    cssVar: '--border-top-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-top', 'var(--border-top-width)']], [`.--${section.sid}--header`, ['margin-top', '-' + 'var(--border-top-width)']]]
                  },
                  {
                    text: '',
                    value: 'right-width',
                    cssVar: '--border-right-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-right', 'var(--border-right-width)']], [`.--${section.sid}--header`, ['margin-right', '-' + 'var(--border-right-width)']]]
                  },
                  {
                    text: '',
                    value: 'bottom-width',
                    cssVar: '--border-bottom-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-bottom', 'var(--border-bottom-width)']], [`.--${section.sid}--header`, ['margin-bottom', '-' + 'var(--border-bottom-width)']]]
                  },
                  {
                    text: '',
                    value: 'left-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="`.--${section.sid}--step`"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--step`"
              rule-name="border"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </template>
          <template #Active>
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="`.--${section.sid}--step.active`"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  :options="[
                    {
                      label: 'Solid',
                      value: 'solid'
                    },
                    {
                      label: 'Dashed',
                      value: 'dashed'
                    },
                    {
                      label: 'Dotted',
                      value: 'dotted'
                    },
                    {
                      label: 'Double',
                      value: 'double'
                    },
                    {
                      label: 'Outset',
                      value: 'outset'
                    }
                  ]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="`.--${section.sid}--step.active`"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                :angles="[
                  {
                    text: '',
                    value: 'top-width',
                    cssVar: '--border-top-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-top', 'var(--border-top-width)']], [`.--${section.sid}--header`, ['margin-top', '-' + 'var(--border-top-width)']]]
                  },
                  {
                    text: '',
                    value: 'right-width',
                    cssVar: '--border-right-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-right', 'var(--border-right-width)']], [`.--${section.sid}--header`, ['margin-right', '-' + 'var(--border-right-width)']]]
                  },
                  {
                    text: '',
                    value: 'bottom-width',
                    cssVar: '--border-bottom-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-bottom', 'var(--border-bottom-width)']], [`.--${section.sid}--header`, ['margin-bottom', '-' + 'var(--border-bottom-width)']]]
                  },
                  {
                    text: '',
                    value: 'left-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="`.--${section.sid}--step.active`"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--step.active`"
              rule-name="border"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </template>
          <template #Hover>
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="`.--${section.sid}--step`"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  presudo=":hover"
                  :options="[
                    {
                      label: 'Solid', 
                      value: 'solid'
                    }, 
                    {
                      label: 'Dashed', 
                      value: 'dashed'
                    }, 
                    {
                      label: 'Dotted', 
                      value: 'dotted'
                    }, 
                    {
                      label: 'Double', 
                      value: 'double'
                    }, 
                    {
                      label: 'Outset', 
                      value: 'outset'
                    }
                  ]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="`.--${section.sid}--step`"
                label="Border Width"
                rule-name="border-width"
                class="!mb-[7px]"
                presudo=":hover"
                :angles="[
                  {
                    text: '',
                    value: 'top-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  },
                  {
                    text: '',
                    value: 'right-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  },
                  {
                    text: '',
                    value: 'bottom-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  },
                  {
                    text: '',
                    value: 'left-width',
                    cssVar: '--border-left-width',
                    customProperties: [[`.--${section.sid}--order-form`, ['border-left', 'var(--border-left-width)']], [`.--${section.sid}--header`, ['margin-left', '-' + 'var(--border-left-width)']]]
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="`.--${section.sid}--step`"
                  rule-name="border-color"
                  presudo=":hover"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--step`"
              rule-name="border"
              presudo=":hover"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup
        v-if="section.options.content.orderForm.stepType !== 'hide'"
        title="Step Padding & Margins"
      >
        <sid-linked-group
          label="Padding"
          :sid="`.--${section.sid}--step`"
          rule-name="padding"
          :min="0"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />

        <sid-linked-group
          label="Margin"
          :sid="`.--${section.sid}--control`"
          rule-name="margin"
          :min="-2000"
          :max="2000"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />
      </SidbearSettingsGroup>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Fields Style">
      <order-form-field-style
        :section="section"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Background">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-background :sid="`.--${section.sid}--order-form`" />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group>
            <sid-background 
              :sid="`.--${section.sid}--order-form`"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--order-form`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  }, 
                  {
                    label: 'Dashed', 
                    value: 'dashed'
                  }, 
                  {
                    label: 'Dotted', 
                    value: 'dotted'
                  }, 
                  {
                    label: 'Double', 
                    value: 'double'
                  }, 
                  {
                    label: 'Outset', 
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--order-form`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width',
                  cssVar: '--border-top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />
                        
            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--order-form`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>
                      
          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--order-form`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--order-form`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[
                  {
                    label: 'Solid', 
                    value: 'solid'
                  }, 
                  {
                    label: 'Dashed', 
                    value: 'dashed'
                  }, 
                  {
                    label: 'Dotted', 
                    value: 'dotted'
                  }, 
                  {
                    label: 'Double', 
                    value: 'double'
                  }, 
                  {
                    label: 'Outset', 
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--order-form`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--order-form`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--order-form`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--order-form`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--order-form`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--content`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--order-form`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapMutations, mapState} from 'vuex'
import shortid from 'shortid'
import ModMixin from '@/components/mixins/ModMixin'
import PropertiesManager from '@/components/mixins/PropertiesManager'
import ThemeMixin from '@/components/mixins/ThemeMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import WizardRuleTabs from '@/components/editor/utils/WizardRuleTabs.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle.vue'
import FormFieldV3 from '@/components/editor/components/form/FormFieldsV3.vue'
import OrderFormFieldStyle from '@/components/editor/components/form/OrderFormFieldStyle.vue'
import SelectProduct from '@/components/editor/components/form/SelectProduct.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import ActionsAfterSubmit from '@/components/editor/components/form/ActionsAfterSubmit.vue'
import TextMixins from '@/components/mixins/TextMixins'
export default {
  name: 'OrderFormMod',
  mixins: [
    ModMixin, 
    TextMixins,
    ThemeMixin,
    PropertiesManager
  ],
  components: {
    ActionsAfterSubmit,
    SidFlexDirection,
    SelectProduct,
    OrderFormFieldStyle,
    FormFieldV3,
    SidFontStyle,
    SidAlign,
    SidRangeSlider,
    SidFont,
    WizardRuleTabs,
    SidBackground,
    SidColor,
    SidSelect,
    SidBoxShadow,
    RuleTabs,
    SidLinkedGroup,
    SidbearSettingsGroup
  },
  data () {
    return {
      activeAction: 0,
      stepTypes: [
        {
          label: 'Tabs',
          value: 'tabs'
        },
        {
          label: 'Progress',
          value: 'progress'
        },
        {
          label: 'Hide',
          value: 'hide'
        }
      ]
    }
  },
  computed: {
    ...mapState('products', {
      loading: state => state.loading,
      products: state => state.products,
      selected: state => state.selected
    }),
    ...mapState('forms', {
      confirmation: state => state.wizard.confirmation,
      currentStep: state => state.wizard.currentStep,
      count: state => state.wizard.count
    }),
    elFieldAuth () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Registration',
        sid: sid,
        hideMod: true,
        hideRight: true,
        component: 'ElFieldAuth',
        fieldSettings: true,
        options: {
          content: {
            control: [
              {
                label: 'First Name',
                placeholder: 'Enter your first name',
                field_name: 'first_name',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                is_hide: false,
                mapping: []
              },
              {
                label: 'Last Name',
                placeholder: 'Enter your last name',
                field_name: 'last_name',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                is_hide: false,
                mapping: []
              },
              {
                label: 'Email',
                placeholder: 'Enter your email address',
                field_name: 'email',
                field_type: 'email',
                values: null,
                validators: '[]',
                is_required: true,
                is_hide: false,
                mapping: []
              },
              {
                label: 'Password',
                placeholder: 'Enter your password',
                field_name: 'password',
                field_type: 'password',
                values: null,
                validators: '[]',
                is_required: true,
                is_hide: false,
                mapping: []
              },
              {
                label: 'Confirm Password',
                placeholder: 'Confirm your password',
                field_name: 'password_confirmation',
                field_type: 'password',
                values: null,
                validators: '[]',
                is_required: true,
                is_hide: false,
                mapping: []
              },
              {
                text: 'Already have account?',
                link: 'login',
                is_hide: false
              }
            ]
          },
          customize: null
        }
      }
    },
    elProductVariant () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Product Variants',
        sid: sid,
        component: 'ElProductVariant',
        fieldSettings: true,
        options: {
          content: {
            productOptions: {
              plan: {
                text: 'Plan'
              },
              price: {
                text: 'Price'
              }
            }
          },
          customize: null
        }
      }
    },
    elPaymentProvider () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Payment Method',
        sid: sid,
        component: 'ElPaymentProvider',
        fieldSettings: true,
        options: {
          content: {
            paymentProvider: {
              label: 'Payment Method'
            },
            providers: []
          },
          customize: null
        }
      }
    },
    elProductCover () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Product Cover',
        sid: sid,
        component: 'ElProductCover',
        fieldSettings: true,
        options: {
          content: {
            productImage: {
              animations: {
                animation: null,
                delay: 50,
                offset: 200,
                once: true
              },
              display: true,
              resolutionStyle: {
                lg: 12,
                md: 12,
                sm: 12
              },
              url: ''
            }
          },
          customize: null
        }
      }
    },
    elProductTitle () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Product Title',
        sid: sid,
        component: 'ElProductTitle',
        fieldSettings: true,
        options: {
          content: {
            productTitle: {
              animations: {
                animation: null,
                delay: 50,
                offset: 200,
                once: true
              },
              display: true,
              resolutionStyle: {
                lg: 12,
                md: 12,
                sm: 12
              },
              text: 'CyberNuts Course'
            }
          },
          customize: null
        }
      }
    },
    elProductDescription () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Product Description',
        sid: sid,
        component: 'ElProductDescription',
        fieldSettings: true,
        options: {
          content: {
            productDescription: {
              animations: {
                animation: null,
                delay: 50,
                offset: 200,
                once: true
              },
              display: true,
              resolutionStyle: {
                lg: 12,
                md: 12,
                sm: 12
              },
              text: 'Sed at neque accumsan, mollis tellus sit amet, egestas justo. Vivamus tellus lacus, blandit vel molestie vitae, varius.'
            }
          },
          customize: null
        }
      }
    },
    elFieldCardDetails () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Credit Card Details',
        sid: sid,
        hideMod: true,
        hideRight: true,
        component: 'ElFieldCardDetails',
        fieldSettings: true,
        options: {
          content: {
            showPaymentMethods: true,
            paymentMethods: {
              visa: true,
              dinersClub: true,
              amex: true,
              discover: true,
              mastercard: true,
              paypal: true
            },
            control: [
              {
                label: 'Payment Method',
                placeholder: null,
                field_name: 'payment_method',
                field_type: 'text',
                values: 'new-method',
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                label: 'Card Number',
                placeholder: null,
                field_name: 'card_number',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                label: 'Expiry Month',
                placeholder: null,
                field_name: 'card_exp_month',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                label: 'Expiry Year',
                placeholder: null,
                field_name: 'card_exp_year',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                label: 'CVC/CVV',
                placeholder: null,
                field_name: 'card_cvc',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              }
            ]
          },
          customize: null
        }
      }
    },
    elPaymentSummary () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Payment Summary',
        sid: sid,
        hideMod: true,
        hideRight: true,
        component: 'ElPaymentSummary',
        fieldSettings: true,
        options: {
          content: {
            paymentSummary: {}
          },
          customize: null
        }
      }
    },
    elBillingTerms () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Billing Terms',
        sid: sid,
        component: 'ElBillingTerms',
        fieldSettings: true,
        options: {
          content: {
            description: {
              animations: {
                animation: null,
                delay: 50,
                offset: 200,
                once: true
              },
              display: true,
              resolutionStyle: {
                lg: 12,
                md: 12,
                sm: 12
              },
              text: '<p>By joining, you accept my <u>terms</u>. You allow them to charge your card $75 now and $75 every month after that. You can cancel anytime.</p>',
              type: 'tiptap-mod-v2'
            }
          },
          customize: null
        }
      }
    },
    firstFieldList () {
      return this.fieldsList(0)
    },
    lastFieldList () {
      return this.fieldsList(this.section.options.content.orderForm.steps.length - 1)
    },
    submitButton () {
      return _.find(this.section.options.content.orderForm.steps[this.currentStep].content, ['class', 'form-button'])
    }
  },
  methods: {
    ...mapMutations({
      SET_SELECTED: 'products/SET_SELECTED'
    }),
    elPreviewsButton () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      return {
        uid: uid,
        name: 'Previews Button',
        sid: sid,
        component: 'ElPreviewsButton',
        fieldSettings: true,
        options: {
          content: {
            previousButton: {
              text: 'Previous Step',
              icon: null
            }
          },
          customize: null
        }
      }
    },
    elNameField () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`

      return {
        uid: uid,
        name: 'FieldInput',
        css: null,
        sid: sid,
        hideMod: true,
        hideRight: true,
        component: 'ElFieldInput',
        fieldSettings: true,
        options: {
          content: {
            control: {
              label: 'Name',
              placeholder: 'Enter your name',
              field_name: 'Name',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            }
          },
          customize: null
        }
      }
    },
    elSubmitButton () {
      const uid = shortid.generate()
      const sid = `${shortid.generate()}--${shortid.generate()}`
      const tempId = shortid.generate()

      return {
        uid: uid,
        display: true,
        name: 'Submit Button',
        css: [[`.--${sid}--1 { width: 100%; --width: 100%; }`], [], []],
        class: 'form-button',
        sid: sid,
        hideMod: true,
        hideRight: true,
        hideFromSections: false,
        component: 'ElButtonV2',
        fieldSettings: true,
        group: 'Form',
        ignoreGroup: false,
        layout: null,
        disableVisibility: false,
        options: {
          content: {
            button: {
              pressetID: 0,
              display: true,
              type: 'button-mod-v2',
              text: 'Click Me',
              buttonType: 'submit',
              realTimeVisibility: true,
              icon: {
                lottie: {
                  jsonPath: '',
                  loop: true,
                  autoplay: true
                }
              },
              hoverAnimation: {},
              animations: {
                animation: null,
                once: true,
                offset: 200,
                delay: 50
              }
            }
          },
          customize: null
        },
        temp_id: tempId,
        editor: 'project'
      }
    },
    setWizardCurrentStep (val) {
      this.$store.dispatch('forms/setWizardCurrentStep', val)
    },
    setWizardCount (val) {
      this.$store.dispatch('forms/setWizardCount', val)
    },
    fieldsList (index) {
      const content = _.chain(this.section.options.content.orderForm.steps[index].content)
        .flatten()
        .filter({fieldSettings: true})
        .value()
      const flex = _.chain(this.section.options.content.orderForm.steps[index].content)
        .filter({component: 'ElFlexWrapperV2'})
        .map('options.content.cols')
        .flatten()
        .map('elements')
        .flatten()
        .filter({fieldSettings: true})
        .value()

      return [
        ...content,
        ...flex
      ]
    },
    removeStep (val) {
      let stepsLength = this.section.options.content.orderForm.steps.length
      const deleteCount = this.section.options.content.orderForm.steps.length - val
      const paymentsElements = ['ElProductVariant', 'ElPaymentProvider', 'ElProductCover', 'ElProductTitle', 'ElProductDescription', 'ElFieldCardDetails', 'ElPaymentSummary', 'ElBillingTerms']
      let tmp = []

      this.fieldsList(stepsLength - 1).forEach(item => {
        if (paymentsElements.includes(item.component)) {
          tmp.push(item)
        }
      })

      this.section.options.content.orderForm.steps.splice(val, deleteCount)

      if (val === 1) {
        this.section.options.content.orderForm.steps[0].content.splice(0, 0, ...tmp)
      } else {
        stepsLength = this.section.options.content.orderForm.steps.length
        this.section.options.content.orderForm.steps[stepsLength - 1].content.splice(0, 0, ...tmp)
      }
    },
    async addStep (val) {
      const stepsLength = this.section.options.content.orderForm.steps.length

      for (let i = stepsLength; i < val; i++) {
        if (i === 1) {
          const paymentsElements = ['ElProductVariant', 'ElPaymentProvider', 'ElProductCover', 'ElProductTitle', 'ElProductDescription', 'ElFieldCardDetails', 'ElPaymentSummary', 'ElBillingTerms']
          let tmp = []

          this.fieldsList(0).forEach(item => {
            if (paymentsElements.includes(item.component)) {
              tmp.push(item)
            }
          })

          this.section.options.content.orderForm.steps.push({
            uui: val,
            form: {
              id: null,
              actions: [],
              api_list_or_tag: {},
              mappedFields: {},
              controls: []
            },
            options: {
              name: 'Step ' + val
            },
            content: [this.elPreviewsButton(), ...tmp, this.elSubmitButton()]
          })
          
          _.remove(this.section.options.content.orderForm.steps[0].content, (item) => {
            return paymentsElements.includes(item.component)
          })
          
          const flexList = _.chain(this.section.options.content.orderForm.steps[0].content)
            .filter({component: 'ElFlexWrapperV2'})
            .map('options.content.cols')
            .flatten()
            .value()

          for (let j = 0; j < flexList.length; j++) {
            const col = flexList[j]

            _.remove(col.elements, (item) => {
              return paymentsElements.includes(item.component)
            })
          }
        } else {
          this.section.options.content.orderForm.steps.splice(i - 1, 0, {
            uui: i,
            form: {
              id: null,
              actions: [],
              api_list_or_tag: {},
              mappedFields: {},
              controls: []
            },
            options: {
              name: 'Step ' + i
            },
            content: [this.elPreviewsButton(), this.elNameField(), this.elSubmitButton()]
          })
          this.section.options.content.orderForm.steps[i].uui = val
          this.section.options.content.orderForm.steps[i].options.name = 'Step ' + val
        }
      }
    },
    updateWizardCount (val) {
      const stepsLength = this.section.options.content.orderForm.steps.length
      if (stepsLength > val) {
        this.removeStep(val)
      } else if (stepsLength < val) {
        this.addStep(val)
      }
      this.setWizardCount(val)
      this.setWizardCurrentStep(val - 1)
    },
    deleteResponder (step, service) {
      for (let c in this.section.options.content.orderForm.steps[step].form.controls) {
        this.$set(this.section.options.content.orderForm.steps[step].form.controls[c], 'mapping', this.section.options.content.orderForm.steps[step].form.controls[c].mapping.filter(con => con.auto_responder_id !== service.id))
      }
    },
    removeAction (step, index) {
      this.section.options.content.orderForm.steps[step].form.actions.splice(index, 1)
    },
    checkButton () {
      this.section.options.content.orderForm.steps.forEach((step, index) => {
        const button = _.find(step.content, {'class': 'form-button'})
        const buttonIndex = _.findIndex(step.content, {'class': 'form-button'})

        if (!button.fieldSettings) {
          const btn = this.elSubmitButton()
          this.section.options.content.orderForm.steps[index].content.splice(buttonIndex, 1)
          this.section.options.content.orderForm.steps[index].content.push(btn)
        }
      })
    },
    checkField () {
      const stepsLength = this.section.options.content.orderForm.steps.length

      const fieldsToCheck = [
        { list: this.firstFieldList, component: 'ElFieldAuth', condition: this.products?.need_account || this.product?.need_account, step: 0, element: this.elFieldAuth },
        { list: this.lastFieldList, component: 'ElProductCover', step: stepsLength - 1, element: this.elProductCover },
        { list: this.lastFieldList, component: 'ElProductTitle', step: stepsLength - 1, element: this.elProductTitle },
        { list: this.lastFieldList, component: 'ElProductDescription', step: stepsLength - 1, element: this.elProductDescription },
        { list: this.lastFieldList, component: 'ElProductVariant', step: stepsLength - 1, element: this.elProductVariant },
        { list: this.lastFieldList, component: 'ElPaymentProvider', step: stepsLength - 1, element: this.elPaymentProvider },
        { list: this.lastFieldList, component: 'ElFieldCardDetails', step: stepsLength - 1, element: this.elFieldCardDetails },
        { list: this.lastFieldList, component: 'ElPaymentSummary', step: stepsLength - 1, element: this.elPaymentSummary },
        { list: this.lastFieldList, component: 'ElBillingTerms', step: stepsLength - 1, element: this.elBillingTerms }
      ]

      fieldsToCheck.forEach(field => {
        const { list, component, condition, step, element } = field
        const fieldExists = _.find(list, ['component', component])

        if (!fieldExists && (condition === undefined || condition)) {
          this.section.options.content.orderForm.steps[step].content.push(element)
        }
      })
    }
  },
  created () {
    if (!this.section.options.content.orderForm.products) {
      this.section.options.content.orderForm.products = []
      this.SET_SELECTED(this.section.options.content.orderForm.products)
    } else {
      this.SET_SELECTED(this.section.options.content.orderForm.products)
    }
    this.checkButton()
    this.checkField()
  },
  watch: {
    selected (val) {
      this.section.options.content.orderForm.products = val
    }
  }
}
</script>