<template>
  <div
    v-if="showCover"
    :class="[`--${section.sid}--wrapper`]"
    :data-uid="section.uid"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      active-class="z-100"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex w-full"
        :data-sid="section.sid"
        :class="[`--${section.sid}--container`]"
      >
        <img
          v-if="product.cover"
          ref="image"
          :class="`--${section.sid}--image`"
          class="el-product-cover img-fluid transition"
          :src="product.cover"
          :alt="product.name"
        >
      </div>
    </highliter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
export default {
  name: 'ElProductCover',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    ...mapState('products', {
      products: state => state.selected
    }),
    isSingleProduct () {
      const singleProduct = this.products.length === 1
      const hasPrices = this.products[0] && this.products[0].prices && this.products[0].prices.length > 0
      return singleProduct && !hasPrices
    },
    showCover () {
      return this.isSingleProduct && this.products[0].cover
    },
    product () {
      return {
        cover: this.products[0].cover,
        name: this.products[0].name
      }
    }
  }
}
</script>

<style lang="scss">
.el-product-cover {
  --height: auto;
  --width: 100%;
  --width-fixed: '';
  --height-fixed: '';
  border-style: solid;
}
</style>