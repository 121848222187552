import { render, staticRenderFns } from "./WizardRuleTabs.vue?vue&type=template&id=13f35edc&"
import script from "./WizardRuleTabs.vue?vue&type=script&lang=js&"
export * from "./WizardRuleTabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports