<template>
  <settings-page-card title="Business Details">
    <div class="px-4 max-w-[900px] mb-[48px]">
      <p class="text-dark font-size-16">
        Your business details are required to sell products or accept payments for this project. This information will
        be public and might be displayed on customer receipts after they make a purchase.
      </p>
    </div>

    <b-form @submit.prevent="onSubmit">
      <div class="px-4">
        <div class="max-w-[600px]">
          <div class="mb-4">
            <label class="font-weight-sami-bold !text-[14px] text-dark">
              Business Logo
            </label>
            <div class="w-[370px]">
              <image-upload
                :image="image"
                class="w-100 mb-3"
                :upload-button-class="{'form-control is-invalid !border-[#dc3545]': !init && !businessDetails.logo}"
                @uploaded="uploaded"
                @imageDeleted="imageDeleted"
              />
              <small class="text-muted">Upload a 400 x 400 pixel <br> PNG, SVG, or JPEG</small>
            </div>
          </div>

          <b-row>
            <b-col md="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Business Name
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.name"
                  v-validate="'required'"
                  name="name"
                  class="!h-[37px]"
                  :state="errors.has('name') ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <b-form-group class="mb-4">
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Address
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.address"
                  v-validate="'required'"
                  name="address"
                  class="!h-[37px]"
                  :state="errors.has('name') ? false : null"
                />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-row>
                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        Country
                      </label>
                    </slot>
                    <country-select
                      v-model="businessDetails.country"
                      v-validate="'required'"
                      class="!h-[37px]"
                      :class="{'form-control is-invalid': !init && !businessDetails.country}"
                      name="country"
                      :state="errors.has('country') ? false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        City
                      </label>
                    </slot>
                    <b-input
                      v-model="businessDetails.city"
                      v-validate="'required'"
                      class="!h-[37px]"
                      name="city"
                      :state="errors.has('city') ? false : null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="10">
              <b-row>
                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        State
                      </label>
                    </slot>
                    <state-select
                      v-model="businessDetails.state"
                      v-validate="'required'"
                      :country="businessDetails.country"
                      class="!h-[37px]"
                      :class="{'form-control is-invalid': !init && !businessDetails.state}"
                      name="state"
                      :state="errors.has('state') ? false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        Zip Code
                      </label>
                    </slot>
                    <b-input
                      v-model="businessDetails.zip_code"
                      v-validate="'required'"
                      class="!h-[37px]"
                      name="zip_code"
                      :state="errors.has('zip_code') ? false : null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Support email
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.support_email"
                  type="email"
                  name="email"
                  class="!h-[37px]"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="phone-number-control align-items-center">
            <b-col cols="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Support Number
                  </label>
                </slot>
                <b-input-group class="align-items-center">
                  <template #prepend>
                    <div style="width: 60px;">
                      <phone-code-select
                        v-model="businessDetails.support_phone_code"
                        class="!h-[37px]"
                      />
                    </div>
                  </template>
                  <div class="dial-code !h-[37px]">
                    {{ businessDetails.support_phone_code.dial_code || '' }}
                  </div>
                  <b-form-input
                    v-model="businessDetails.support_phone"
                    :maxlength="15"
                    class="!h-[37px]"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-btn
          variant="primary"
          type="submit"
          class="mt-[12px]"
        >
          Save Changes
        </b-btn>
      </div>
    </b-form>
  </settings-page-card>
</template>
<script>
import {mapState} from 'vuex'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard.vue'
import CountrySelect from '@/layouts/dashboard/compontens/account/general/components/CountrySelect.vue'
import StateSelect from '@/layouts/dashboard/compontens/account/general/components/StateSelect.vue'
import PhoneCodeSelect from '@/layouts/dashboard/compontens/account/general/components/PhoneCodeSelect.vue'
import ImageUpload from '@/components/editor/utils/ImageUpload.vue'
export default {
  name: 'BusinessDetails',
  components: {
    ImageUpload,
    PhoneCodeSelect,
    StateSelect, CountrySelect,
    SettingsPageCard
  },
  data () {
    return {
      init: true,
      image: {
        src: ''
      }
    }
  },
  computed: {
    ...mapState('projects', {
      businessDetails: state => state.businessDetails
    })
  },
  methods: {
    uploaded (image) {
      VEvent.fire('loader', true)
      this.businessDetails.logo = image.src
      VEvent.fire('loader', false)
    },
    imageDeleted () {
      this.uploaded({src: ''})
    },
    onSubmit () {
      this.init = false
      this.$validator.validateAll().then(async (result) => {
        if (result && this.businessDetails.logo) {
          VEvent.fire('loader', true)
          await axios.post(`/api/projects/${this.$route.params.id}/business-info`, {
            business_info: {
              support_email: this.businessDetails.support_email,
              support_phone: this.businessDetails.support_phone,
              support_phone_code: this.businessDetails.support_phone_code,
              logo: this.businessDetails.logo,
              name: this.businessDetails.name,
              address: this.businessDetails.address,
              country: this.businessDetails.country,
              city: this.businessDetails.city,
              state: this.businessDetails.state,
              zip_code: this.businessDetails.zip_code
            }
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Success',
                showConfirmButton: false,
                timer: 3000
              })
            })
            .catch(errors => {
              const arrErrors = Object.keys(errors.response.data.errors),
                errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: errorMsg,
                showConfirmButton: false,
                timer: 3000
              })
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
        }
      })
    }
  },
  mounted () {
    this.image.src = this.businessDetails.logo
    if (!this.businessDetails.address || !this.businessDetails.city || !this.businessDetails.state || !this.businessDetails.country || !this.businessDetails.zip_code || !this.businessDetails.support_email || !this.businessDetails.support_phone_code || !this.businessDetails.support_phone) {
      axios.get('/api/user/profile')
        .then(({data}) => {
          console.log(data)
          if (data.data.shipping_information) {
            this.businessDetails.address = data.data.shipping_information['address']
            this.businessDetails.city = data.data.shipping_information['city']
            this.businessDetails.state = data.data.shipping_information['state_id'] ? Number(data.data.shipping_information['state_id']) : ''
            this.businessDetails.country = data.data.shipping_information['country_id'] ? Number(data.data.shipping_information['country_id']) : ''
            this.businessDetails.zip_code = data.data.shipping_information['zip_code']
          } else {
            this.businessDetails.address = ''
            this.businessDetails.city = ''
            this.businessDetails.state = ''
            this.businessDetails.country = ''
            this.businessDetails.zip_code = ''
          }
          this.businessDetails.support_email = data.data['email']
          this.businessDetails.support_phone_code = JSON.parse(data.data['phone_code']) || {name: 'United States', dial_code: '+1', code: 'US'}
          this.businessDetails.support_phone = data.data['phone']
        })
    }
  }
}
</script>