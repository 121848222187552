<template>
  <div class="border mt-[-1px] mb-[10px] gap-y-[12px]">
    <div
      :class="active === index ? 'border-b rounded-b-0' : null"
      class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter"
      @click="$emit('open-settings')"
    >
      <svg
        class="move cursor-move mr-[14px]"
        fill="none"
        height="10"
        viewBox="0 0 10 10"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.57745 0H0.568359V3H3.57745V0Z"
          fill="#3D82EA"
        />
        <path
          d="M10.5984 0H7.58936V3H10.5984V0Z"
          fill="#3D82EA"
        />
        <path
          d="M3.57745 7H0.568359V10H3.57745V7Z"
          fill="#3D82EA"
        />
        <path
          d="M10.5984 7H7.58936V10H10.5984V7Z"
          fill="#3D82EA"
        />
      </svg>

      <span 
        class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA]"
        :class="active === index ? 'text-[#3D82EA]' : 'text-[#7E7E7E]'"
      >
        {{ !componentName? control.options.content.control.field_name : control.name }}
      </span>

      <button
        title="Column Settings"
        class="flex h-[38px] items-center justify-center w-[38px] ml-auto p-0 bg-transparent hover:bg-[#E2E5EC]"
        :class="{'rotate-90': active === index}"
        @click.stop="$emit('open-settings')"
      >
        <svg
          fill="none"
          height="10"
          viewBox="0 0 7 10"
          width="7"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
            stroke="#6E747A"
            stroke-width="1.5"
          />
        </svg>
      </button>

      <button
        v-if="!disableRemove"
        v-b-tooltip
        class="flex h-[38px] items-center justify-center border-left w-[38px] p-0 bg-transparent hover:bg-[#E2E5EC]"
        title="Delete"
        @click.stop="deleteControl"
      >
        <svg
          fill="none"
          height="10"
          viewBox="0 0 10 10"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
            fill="#6E747A"
          />
        </svg>
      </button>
    </div>
    
    <component
      :is="settingsComponent(control.component)"
      v-if="active === index"
      :control="control.options.content.control"
      :element="control"
      :form="config"
      :config="control"
      :disable-remove="disableRemove"
      :section="section"
      :sid-class="`.--${control.sid}--form-field`"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import AuthSettings from '@/components/editor/components/form/field-settings/AuthSettings.vue'
import SubmitButtonSettings from '@/components/editor/components/form/field-settings/SubmitButtonSettings.vue'
import BillingTermsSettings from '@/components/editor/components/form/field-settings/BillingTermsSettings.vue'
import CreditCardSettings from '@/components/editor/components/form/field-settings/CreditCardSettings.vue'
import FormControlsFieldSettingsV2 from '@/components/editor/components/form/FormControlsFieldSettingsV2.vue'
import PaymentSummarySettings from '@/components/editor/components/form/field-settings/PaymentSummarySettings.vue'
import ProductVariantSettings from '@/components/editor/components/form/field-settings/ProductVariantSettings.vue'
import PaymentProviderSettings from '@/components/editor/components/form/field-settings/PaymentProviderSettings.vue'
import ProductCoverSettings from '@/components/editor/components/form/field-settings/ProductCoverSettings.vue'
import ProductDescriptionSettings
  from '@/components/editor/components/form/field-settings/ProductDescriptionSettings.vue'
import ProductTitleSettings from '@/components/editor/components/form/field-settings/ProductTitleSettings.vue'
import PreviewButtonSettings from '@/components/editor/components/form/field-settings/PreviewButtonSettings.vue'
export default {
  name: 'FormControlsListItemV2',
  components: {
    AuthSettings,
    SubmitButtonSettings,
    BillingTermsSettings,
    CreditCardSettings,
    FormControlsFieldSettingsV2,
    PaymentSummarySettings,
    ProductVariantSettings,
    PaymentProviderSettings,
    ProductCoverSettings,
    ProductDescriptionSettings,
    ProductTitleSettings,
    PreviewButtonSettings
  },
  props: {
    active: {
      type: Number,
      required: false,
      default: null
    },
    index: {
      type: Number,
      required: false,
      default: null
    },
    control: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('products', {
      products: state => state.products
    }),
    ...mapGetters('auth', {
      isSuperAdmin: 'isSuperAdmin',
      isSupport: 'isSupport'
    }),
    needAccount () {
      return this.products?.need_account || true
    },
    disableRemove () {
      const items = [
        'ElPreviewsButton',
        'ElButtonV2',
        'ElFieldCardDetails',
        'ElPaymentSummary',
        'ElBillingTerms',
        'ElProductVariant',
        'ElPaymentProvider',
        'ElProductTitle',
        'ElProductDescription',
        'ElProductCover',
        this.needAccount ? 'ElFieldAuth' : null
      ]
      return _.includes(items, this.control.component)
    },
    componentName () {
      const items = [
        'ElPreviewsButton',
        'ElButtonV2',
        'ElFieldAuth',
        'ElFieldCardDetails',
        'ElPaymentSummary',
        'ElBillingTerms',
        'ElProductVariant',
        'ElPaymentProvider',
        'ElProductTitle',
        'ElProductDescription',
        'ElProductCover'
      ]
      return _.includes(items, this.control.component)
    }
  },
  methods: {
    deleteControl () {
      this.$emit('deleted')
    },
    settingsComponent (name) {
      if (name === 'ElButtonV2' && _.includes(['form-button', 'form-button-redirect'], this.control.class)) return 'SubmitButtonSettings'
      else {
        switch (name) {
        case 'ElPreviewsButton': return 'PreviewButtonSettings'
        case 'ElFieldAuth': return 'AuthSettings'
        case 'ElBillingTerms': return 'BillingTermsSettings'
        case 'ElFieldCardDetails': return 'CreditCardSettings'
        case 'ElPaymentSummary': return 'PaymentSummarySettings'
        case 'ElProductVariant': return 'ProductVariantSettings'
        case 'ElPaymentProvider': return 'PaymentProviderSettings'
        case 'ElProductTitle': return 'ProductTitleSettings'
        case 'ElProductDescription': return 'ProductDescriptionSettings'
        case 'ElProductCover': return 'ProductCoverSettings'
        default: return 'FormControlsFieldSettingsV2'
        }
      }
    }
  }
}
</script>