import DashboardLayout from '../layouts/dashboard/DashboardLayout'
import Dashboard from '../views/dashboard/Dashboard'
import Login from '../views/login/Login'
// import store from '../store'
import Builder from '../views/builder/Builder'
// import Register from '../views/login/Register'

import EsRoot from '../components/builder/EsRoot'
// import Integrations from '../views/dashboard/Integrations/IntegrationsLayout'
import Settings from '../layouts/dashboard/Settings'
import EsBlog from '../views/blog/EsBlog'
import EsModal from '../views/modal/EsModal'
import Modules from '../views/modules/Modules'
import Domain from '../views/domain/Domain'
import General from '../views/dashboard/General'
import InjectionModal from '../components/editor/modals/InjectionModal'
import PostEditor from '../views/blog/PostEditor'
import ModalEditor from '../views/modal/ModalEditor'
import BlogSettings from '../views/blog/BlogSettings'
import GridThreeWrapper from '../templates/blog/grid-3/GridThreeWrapper'
import Share from '../views/dashboard/Share'
import SocialShare from '../views/dashboard/SocialShare'
import ProjectImport from '../views/import/ProjectImport'
import ResetPassword from '../views/login/ResetPassword'
import NotFoundPage from '../views/dashboard/NotFoundPage'
import SelectTamplate from '../views/dashboard/SelectTamplate'
import CookieConsent from '../views/dashboard/CookieConsent'
import Recaptcha from '../views/dashboard/Recaptcha'
import Account from '../layouts/dashboard/Account'
import {default as Profile} from '../layouts/dashboard/compontens/account/general/Main'
import {default as LoginInfo} from '../layouts/dashboard/compontens/account/login-info/Main'
import {default as Billing} from '../layouts/dashboard/compontens/account/billing/Main.vue'
import {default as Plan} from '../layouts/dashboard/compontens/account/plan/Main'
import Archive from '../views/dashboard/Archive'
import PageRestrictions from '../views/dashboard/PageRestrictions'
import ProjectSeo from '../views/dashboard/seo/ProjectSeo'
import BlogManageCommnets from '../views/blog/comments/BlogManageCommnets'
import ManagePost from '../views/blog/ManagePost'
import ProjectFonts from '@/views/dashboard/fonts/ProjectFonts'
import TemplatePurchase from '@/views/dashboard/TemplatePurchase'
// import CloudflareEnterprise from '@/views/cloudflare/CloudflareEnterprise'
/* Integrations */
import IntegrationsLayout from '@/views/dashboard/integration/IntegrationsLayout'
import Analytics from '@/views/dashboard/integration/analytics/Analytics'
import Autoresponders from '@/views/dashboard/integration/autoresponders/Autoresponders'
import Chat from '@/views/dashboard/integration/chat/Chat'
import Marketing from '@/views/dashboard/integration/marketing/Marketing'
import PaymentProccesing from '@/views/dashboard/integration/payment-processing/PaymentProccesing'
import router from '@/router'
import EsMegaMenu from '@/views/mega-menu/EsMegaMenu'
import MegaMenuEditor from '@/views/mega-menu/MegaMenuEditor'
import BlogHomeEditor from '@/views/blog/templates/BlogHomeEditor.vue'
import PostTemplateEditor from '@/views/blog/post-template/PostTemplateEditor.vue'
import PostContentTemplateEditor from '@/views/blog/PostContentTemplateEditor.vue'
import GlobalVariables from '@/views/dashboard/variables/GlobalVariables.vue'
import GlobalTracking from '@/views/dashboard/global-tracking/GlobalTracking.vue'
import CloudflareEnterprise from '@/views/cloudflare/CloudflareEnterprise.vue'
import CommentsDashboard from '@/views/dashboard/comments/CommentsDashboard.vue'
import Blog from '@/views/dashboard/Blog.vue'
import Dev from '@/views/dev/Dev.vue'
import Welcome from '@/views/welcome/Welcome.vue'
import Reactivate from '@/views/dashboard/reactivate/Reactivate.vue'
import CommunityDashboard from '@/views/dashboard/community/CommunityDashboard.vue'
import Signup from '@/views/login/Signup.vue'
import BusinessDetails from '@/views/dashboard/BusinessDetails.vue'
import Maintenance from '@/views/maintenance/Maintenance.vue'

export default [
  {
    path: '/bloger',
    component: GridThreeWrapper
  },
  {
    path: '/reactivate',
    name: 'reactivate',
    component: Reactivate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/abs-welcome',
    component: Welcome,
    name: 'welcome'
  },
  {
    path: '/templates',
    component: SelectTamplate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/template/:id',
    component: TemplatePurchase,
    name: 'template',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/settings/:id',
        component: Settings,
        name: 'settings',
        children: [
          {
            name: 'settings.general',
            path: 'general-settings',
            component: General
          },
          {
            name: 'settings.blog',
            path: 'blog-settings',
            component: Blog
          },
          {
            name: 'settings.domain',
            path: 'domain-settings',
            component: Domain
          },
          {
            name: 'settings.cloudflare-enterprise',
            path: 'cloudflare-enterprise',
            component: CloudflareEnterprise
            // beforeEnter (to) {
            //   const user = store.state.auth.user
            //   if (user.cf_enterprise) {
            //     return false
            //   } else {
            //     return to.path
            //   }
            // }
          },
          // {
          //   name: 'settings.integrations',
          //   path: 'integrations',
          //   component: Integrations
          // },
          {
            name: 'settings.injection',
            path: 'injection',
            component: InjectionModal
          },
          {
            name: 'settings.page_restrictions',
            path: 'page-restrictions',
            component: PageRestrictions
          },
          {
            name: 'settings.share',
            path: 'share',
            component: Share
          },
          {
            name: 'settings.social_share',
            path: 'social-share',
            component: SocialShare
          },
          {
            name: 'settings.cookie',
            path: 'cookie',
            component: CookieConsent
          },
          {
            name: 'settings.recaptcha',
            path: 'recaptcha',
            component: Recaptcha
          },
          {
            name: 'settings.business-details',
            path: 'business-details',
            component: BusinessDetails
          },
          {
            name: 'settings.seo',
            path: 'seo',
            component: ProjectSeo
          },
          {
            name: 'settings.variables',
            path: 'variables',
            component: GlobalVariables
          },
          {
            name: 'settings.global-tracking',
            path: 'global-tracking',
            component: GlobalTracking
          },
          {
            name: 'project.comments',
            path: 'comments-management',
            component: CommentsDashboard
          },
          {
            name: 'project.community',
            path: 'community',
            component: CommunityDashboard
          },
          {
            path: 'modal',
            name: 'module.modal',
            component: EsModal
          },
          {
            path: 'mega-menu',
            name: 'module.mega-menu',
            component: EsMegaMenu
          },
          {
            path: 'blog',
            name: 'module.blog',
            component: EsBlog,
            children: [
              {
                path: 'manage/comments/:post_id',
                name: 'blog.manage-comments',
                component: BlogManageCommnets,
                meta: {
                  viewport: true
                }
              },
              {
                path: 'manage/posts',
                name: 'blog.manage-posts',
                component: ManagePost
              }
            ]
          },
          {
            name: 'settings.integrations',
            path: 'integrations',
            component: IntegrationsLayout,
            redirect:{name: 'settings.integrations.autoresponders'},
            children:[
              {
                name: 'settings.integrations.analytics',
                path: 'analytics',
                component: Analytics
              },
              {
                name: 'settings.integrations.autoresponders',
                path: 'autoresponders',
                component: Autoresponders
              },
              {
                name: 'settings.integrations.chat',
                path: 'chat',
                component: Chat
              },
              {
                name: 'settings.integrations.marketing',
                path: 'marketing',
                component: Marketing
              },
              {
                name: 'settings.integrations.payment-processing',
                path: 'payment-processing',
                component: PaymentProccesing
              }
            ]
          },
          {
            name: 'settings.fonts',
            path: 'fonts',
            component: ProjectFonts
          }
        ]
      },
      {
        name: 'settings.not-found-page',
        path: '/settings/:id/not-found-page',
        component: NotFoundPage
      },
      {
        name: 'project.import',
        path: 'project-import/:id',
        component: ProjectImport
      },
      {
        path: '/builder',
        component: Builder,
        name: 'builder',
        children: [
          {
            path: '/builder/:id/modules/blog/settings',
            name: 'blog.settings',
            component: BlogSettings
          },
          {
            path: '/builder/:id',
            name: 'editor',
            component: EsRoot,
            meta: {
              editor: true
            }
          },
          {
            path: '/builder/:id/modules',
            name: 'modules',
            component: Modules
          },
          {
            name: 'modal.editor',
            path: '/builder/:id/editor/modal/:modal_id',
            component: ModalEditor,
            meta: {
              editor: true
            }
          },
          {
            name: 'mega-menu.editor',
            path: '/builder/:id/editor/mega-menu/:menu_id',
            component: MegaMenuEditor,
            meta: {
              editor: true
            }
          },
          {
            name: 'editor.post',
            path: '/builder/:id/editor/blog-post/:post_id',
            component: PostEditor,
            meta: {
              name: 'Single Post Page',
              editor: true
            }
          },
          {
            name: 'editor.blog-post-template',
            path: '/builder/:id/editor/blog-post-template/:template_id',
            component: PostTemplateEditor,
            meta: {
              name: 'Post Template Page',
              editor: true
            }
          },
          {
            name: 'editor.blog-post-content-template',
            path: '/builder/:id/editor/blog-post-content-template/:template_id',
            component: PostContentTemplateEditor,
            meta: {
              name: 'Post Content Template Page',
              editor: true
            }
          },
          {
            name: 'editor.blog-home',
            path: '/builder/:id/editor/blog-home/:template_id',
            component: BlogHomeEditor,
            meta: {
              name: 'Blog Home Page',
              editor: true
            }
          },
          {
            path: '/builder/:id/modules/modal',
            name: 'module.modal',
            component: EsModal
          },
          {
            path: '/builder/:id/modules/mega-menu',
            name: 'module.mega-menu',
            component: EsMegaMenu
          },
          {
            path: '/builder/:id/modules/domain',
            name: 'module.domain',
            component: Domain
          }
        ]
      },
      {
        name: 'account',
        path: '/account',
        component: Account,
        children: [
          {
            name: 'account.profile',
            path: 'profile',
            component: Profile
          },
          {
            name: 'account.login-info',
            path: 'login-info',
            component: LoginInfo
          },
          {
            name: 'account.plan',
            path: 'plan',
            component: Plan
          },
          {
            name: 'account.billing',
            path: 'billing',
            component: Billing
          }
        ]
      },
      {
        name: 'dev',
        path: '/dev',
        component: Dev
      }
    ]
  },
  {
    name: 'archive-projects',
    path: '/archive/projects',
    component: Archive
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter (to, from, next) {
      const url = router.resolve(to)

      if (process.env.NODE_ENV !== 'development') {
        window.location.href = process.env.VUE_APP_AUTH_HOST + url.href
      }

      next()
    }
  },
  {
    name: 'signup',
    path: '/signup',
    component: Signup
  },
  {
    path: '/reset-password-form',
    name: 'reset-password',
    component: ResetPassword,
    beforeEnter (to, from, next) {
      const url = router.resolve(to)

      if (process.env.NODE_ENV !== 'development') {
        window.location.href = process.env.VUE_APP_AUTH_HOST + url.href
      }

      next()
    }
  },
  {
    name: 'maintenance',
    path: '/maintenance',
    component: Maintenance
  }
]
