<template>
  <div
    class="h-[75px] bg-gradient-360 from-per-[0%] to-per-[36.65%] from-gray-10 to-white shadow-[0_2px_4px_rgba(0,0,0,0.0553158)]"
  >
    <div
      :class="{'dashboard-header-disabled':disabled}"
      class="dashboard-container h-full flex items-center justify-between"
    >
      <img
        class="h-[17px] w-[134.06px] cursor-pointer"
        src="@/assets/icons/main-logo.svg"
        alt="Logo"
        @click="handleClickOnLogo"
      >
      <div class="flex items-center">
        <b-btn
          v-if="$route.path === '/templates'"
          to="/"
          class="!hidden sm:!flex align-items-center mr-md-4 mb-4 ml-auto mb-md-0"
        >
          Back to dashboard
        </b-btn>

        <b-dropdown
          toggle-class="text-decoration-none bg-transparent border-0 p-0 inline-flex !w-[20px] !h-[20px]"
          menu-class="!mt-[20px] sm:!mr-[-200px] !mr-[-25px] border-0 p-0 bg-transparent"
          right
        >
          <template slot="button-content">
            <img
              src="https://hub.estage.com/svg/menu-dots.svg"
              alt=""
            >
          </template>
          <img
            src="https://hub.estage.com/svg/triangle.svg"
            class="absolute top-[-14px] left-[190px] sm:left-[250px]"
          >
          <div
            class="w-[236px] sm:w-[472px] flex flex-wrap bg-white font-good-times shadow-light rounded overflow-hidden"
          >
            <div
              v-for="(service, index) in services"
              :key="index"
              class="basis-full sm:basis-1/2 h-[70px] text-[13px] bg-white flex items-center p-[16px] sm:[&:nth-child(odd)]:border-r sm:[&:nth-child(odd)]:border-r-gray-8 border-t border-t-gray-8 [&:nth-child(1)]:border-t-0 sm:[&:nth-child(2)]:border-t-0"
              :class="{
                'cursor-pointer hover:bg-gradient-180 hover:from-per-[0%] hover:from-white hover:to-gray-8':
                  !service.disabled,
                'text-[#C6CAD3]': service.disabled,
              }"
              @click="openServiceRoute(service)"
            >
              <img
                class="mr-[13px] w-[20px] h-[20px]"
                src="@/assets/icons/logo.svg"
                alt="Logo ESTAGE"
              >

              {{ service.title }}
            </div>
          </div>
        </b-dropdown>

        <div
          class="ml-[30px] flex gap-4 items-center"
        >
          <b-dropdown
            toggle-class="text-decoration-none !text-black inline-flex w-[320px] !bg-white hover:!bg-gray-50 border !h-[38px] font-good-times rounded basis-full sm:basis-1/2 h-[44px] !text-[14px] flex items-center p-[1rem]"
            menu-class="!mt-[20px] border-0 p-0 bg-transparent"
            right
          >
            <template slot="button-content">
              <img
                class="mr-[12px] w-[20px] h-[20px]"
                src="@/assets/icons/logo.svg"
                alt="Logo ESTAGE"
              >

              {{ services[1].title }}
              <img
                src="https://hub.estage.com/svg/chevron-down.svg"
                class="h-2 w-3 ml-auto"
                alt=""
              >  
              <!--                            <img-->
              <!--                              src="@/assets/svg/dropdown-toggle.svg"-->
              <!--                              alt=""-->
              <!--                            >-->
            </template>
            <img
              src="https://hub.estage.com/svg/triangle.svg"
              class="absolute top-[-14px] left-[190px] sm:left-[250px]"
            >
            <div
              class="w-[320px] bg-white font-good-times shadow-light rounded overflow-hidden"
            >
              <div
                v-for="(service, index) in services"
                :key="index"
                class="basis-full sm:basis-1/2 h-[44px] text-[12px] bg-white flex items-center p-[1rem] sm:[&:nth-child(odd)]:border-r sm:[&:nth-child(odd)]:border-r-gray-8 border-t border-t-gray-8 [&:nth-child(1)]:border-t-0"
                :class="{
                  'cursor-pointer hover:bg-gradient-180 hover:from-per-[0%] hover:from-white hover:to-gray-8':
                    !service.disabled,
                  'text-[#C6CAD3]': service.disabled,
                }"
                @click="openServiceRoute(service)"
              >
                <img
                  class="mr-[13px] w-[20px] h-[20px]"
                  src="@/assets/icons/logo.svg"
                  alt="Logo ESTAGE"
                >

                {{ service.title }}
              </div>
            </div>
          </b-dropdown>
          
          <b-dropdown
            toggle-class="p-0 !bg-transparent border-0"
            menu-class="!mt-[10px] border-0 p-0 bg-transparent"
            right
          >
            <template slot="button-content">
              <div class="hidden sm:inline-flex px-[12px] h-[38px] w-[204px] justify-between items-center border border-gray-150 rounded-[3px] hover:!bg-gray-50 focus:outline-none focus:bg-gray-50">
                <div class="flex items-center">
                  <div
                    class="w-[20px] h-[20px] bg-gray-650 rounded-full flex items-center justify-center mr-2 overflow-hidden"
                  >
                    <img
                      v-if="user && user.image"
                      :src="user.image"
                      class="h-full w-full object-cover"
                    >
                    <img
                      v-else
                      src="@/assets/icons/avatar-placeholder.svg"
                      class="h-3"
                      alt=""
                    >
                  </div>
                  <span class="text-[15px] text-gray-650">Account</span>
                </div>
                <img
                  src="https://hub.estage.com/svg/chevron-down.svg"
                  class="h-2 w-3"
                  alt=""
                >
              </div>
              <img
                class="w-[22px] h-[22px] block sm:hidden"
                src="@/assets/icons/hamburger-menu.svg"
                alt="Open main menu"
              >
            </template>
            <ul class="bg-white w-[204px] border !shadow-sm !border-gray-150 !rounded-[3px]">
              <router-link
                :to="{name: 'account.profile'}"
                class="block hover:bg-gray-50 p-[12px] !text-[15px] !text-gray-650 !no-underline"
              >
                Settings
              </router-link>
              <a
                href="javascript:;"
                class="block hover:bg-gray-50 p-[12px] !text-[15px] !text-gray-650 !no-underline"
                @click="$store.dispatch('auth/signOut')"
              > Logout </a>
            </ul>
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modal"
      hide-footer
      title="Access Denied"
    >
      ESTAGE Ambassador Center is opening soon!
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DashboardHeader',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      zenDesk: null,
      modal: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('portalServices', {
      courses: state => state.courses
    }),
    byName: {
      get () {
        return this.$store.state.filter.byName
      },
      set (val) {
        this.$store.commit('filter/SET_BY_NAME', val)
      }
    },
    services () {
      const data = [
        {
          title: 'Dashboard',
          route: process.env.VUE_APP_WELCOME_HOST,
          target: '_self'
        },
        {
          title: 'Projects & Hubs',
          route: '/',
          onlyInMenu: true,
          target: '_self'
        },

        //   {
        //     title: 'HUB Academy',
        //     desc: 'Everything you need to know about building a thriving HUB for your brand in the new HUB Economy!',
        //     disabled: false,
        //     btn_text: 'Join Academy',
        //     img: '/svg/services/hub.svg',
        //     img_class: 'translate-x-[-12px]',
        //   },
        //   {
        //     title: 'Community',
        //     desc: 'Meet and interact with your fellow ESTAGE insiders, build things together, and change the world!',
        //     disabled: false,
        //     btn_text: 'Join Now',
        //     img: '/svg/services/community.svg',
        //   },
        // {
        //   title: 'Services',
        //   desc: 'Want us to build your project or a create a complete HUB for your business or brand? You got it!',
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/services.svg',
        // },
        // {
        //   title: 'Insider Summit',
        //   desc: 'Our annual ESTAGE International Conference is a game-changer. Tickets, recordings, and more inside!',
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/summit.svg',
        // },
        // {
        //   title: 'Certifications',
        //   desc: 'Officially become ESTAGE Certified and unlock a whole new world of possibilities and business potential!',
        //   disabled: false,
        //   btn_text: 'Get Certified',
        //   img: '/svg/services/certifications.svg',
        // },
        // {
        //   title: 'AD Network',
        //   desc: "We'll keep this hush-hush for now but stay tuned... 😉",
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/add.svg',
        // },
        // {
        //   title: 'App Developer',
        //   desc: "We'll keep this hush-hush for now but stay tuned... 😉",
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/developing.svg',
        //   img_class: 'translate-x-[39px] translate-y-[10px]',
        // },
        // {
        //   title: 'Estage Store',
        //   desc: 'Love ESTAGE? Get your ESTAGE Insider swag on! Welcome to the ESTAGE shopping mall.',
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/store.svg',
        //   img_class: 'translate-x-[10px] translate-y-[6px]',
        // },
        // {
        //   title: 'Estage Store',
        //   desc: 'Love ESTAGE? Get your ESTAGE Insider swag on! Welcome to the ESTAGE shopping mall.',
        //   disabled: true,
        //   btn_text: 'Coming Soon',
        //   img: '/svg/services/store.svg',
        //   img_class: 'translate-x-[10px] translate-y-[6px]',
        // },
        {
          title: 'Community & Training',
          desc: 'Step into our private community and unlock a world of knowledge, connections, live interactions, and endless fun!',
          disabled: false,
          btn_text: 'Open Community',
          img: '/svg/services/community.png',
          route: process.env.VUE_APP_COMMUNITY_HOST,
          target: '_blank'
        },
        {
          title: 'Video Hosting',
          desc: 'Step into our private community and unlock a world of knowledge, connections, live interactions, and endless fun!',
          disabled: false,
          btn_text: 'Open Community',
          img: '/svg/services/community.png',
          route: 'https://video.estage.com/',
          target: '_blank'
        },
        {
          title: 'Support Center',
          desc: 'Meet and interact with your fellow ESTAGE insiders, build things together, and change the world!',
          disabled: false,
          btn_text: 'Open Support Center',
          img: '/svg/services/success-center.svg',
          img_style: 'transform:translateY(10px)',
          route: this.zenDesk,
          target: '_blank'
        }
      ]

      // const whiteList = [11]
      // for (const course of this.courses) {
      //   if (!whiteList.includes(course.id)) continue
      //   data.splice(3, 0, {
      //     title: course.title,
      //     desc: course.short_content,
      //     disabled: false,
      //     btn_text: 'Join Now',
      //     img: course.cover,
      //     // img_class: 'translate-x-[-12px]',
      //     route: process.env.VUE_APP_WELCOME_HOST + '/course/' + course.id + '/lesson'
      //   })
      // }

      data.forEach((d) => {
        d.uuid = _.uniqueId('service_')
      })

      return data
    }
  },
  created () {
    axios.get('api/user/support/zendesk')
      .then(({data}) => {
        this.zenDesk = data.data
      })
    if(!this.courses.length) this.getCourses()

  },
  methods: {
    ...mapActions({
      getCourses:'portalServices/getCourses'
    }),
    openServiceRoute (service){
      if (!service.route || (service.route && service.route === this.$route.path )) {
        return document.body.click()
      }
      if (service.route.startsWith('/')) {
        this.$router.push(service.route)
      } else {
        if (service.title === 'Ambassador Center') {
          this.modal = true
        } else {
          window.open(service.route,service.target || '_self')
        }
      }
    },
    handleClickOnLogo (){
      location.replace(process.env.VUE_APP_WELCOME_HOST)
    }
  }
}
</script>

<style lang="scss">
.dashboard-header-disabled{
  opacity: .5;
  pointer-events: none;
}
.header-logo-separate {
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  color: #6E747A;

  &:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 1px;
    background-color: #DDDFE2;
  }
}
</style>
