<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    v-bind="highliterOptions"
    :dublicate-deep="parentUID"
    class="highlight-section"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="() => $emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('block-mod')"
  >
    <template #right-panel>
      <i
        v-if="userRole.includes('support') || userRole.includes('superadministrator')"
        class="fa fa-upload hover:!text-white hover:opacity-60 ml-3"
        @click.stop="addToBlocks"
      />
    </template>

    <div class="w-100 d-flex justify-center">
      <div
        class="d-flex w-100 justify-center max-w-[70vw] el-container relative p-[20px]"
        :data-sid="section.sid"
        :class="[`--${section.sid}--container`, `--${section.sid}--wrapper`, {'element-hidden': !visability()}]"
      >
        <div class="w-100">
          <div
            class="mask-overlay block-bg inherit-radius"
          />

          <div
            class="block-bg"
            :class="`--${section.sid}--bg`"
          >
            <div 
              class="block-bg"
              :class="`--${section.sid}--image`"
            />
          </div>

          <div
            class="block-bg flex overflow-hidden"
            :class="`--lottie-container-${section.sid}`"
          >
            <button-lottie-icon
              v-if="section.options.content.lottie"
              :config="section.options.content"
            />
          </div>

          <background-video
            v-if="section.options.backgroundVideo.url && section.options.backgroundVideo.enabled"
            :options="section.options.backgroundVideo"
            :class="`--${section.sid}--video`"
            class="inherit-radius"
          />

          <background-slider-v2
            :options="section.options.backgroundSlider"
            :section="section"
            class="bg inherit-radius"
          />

          <div
            class="el-overlay inherit-radius"
          />

          <img
            class="float-image-wrapper"
            alt="Float Image"
          >

          <shape-divider
            :config="section.options"
          />

          <div class="flex justify-center position-relative">
            <div
              class="items-center el-block block-container w-full h-full"
            >
              <elements
                v-model="section.options.content.items.children"
                class="contents"
                classes="contents"
                drag-height="200px"
                group-name="rows"
                :put="['rows']"
                :custom-add-subject="addSubject"
                @flow="handleFlow"
                @add="onAddedRow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-to-favorite-modal
      :favorite="favoriteBlock"
      @submit="favorite"
    />

    <add-to-blocks-modal
      :block="publicBlock"
    />
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter'
import SectionMixin from '@/components/mixins/SectionMixin'
import {insert} from '@/sections/utils/helpers'
import shortid from 'shortid'
import DraggableElementsHandler from '@/components/mixins/DraggableElementsHandler'
import BackgroundVideo from '@/components/builder/utils/background-video/BackgroundVideo'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import BackgroundSliderV2 from '@/components/builder/utils/BackgroundSliderV2'
import AddToFavoriteModal from '@/components/builder/components/modals/AddToFavoriteModal'
import {clonePart} from '@/utils/useStylesheet'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon'
import Elements from '@/components/builder/utils/Elements/Elements'
import AddToBlocksModal from '@/components/builder/components/modals/AddToBlockModal.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'BlankV2',
  components: {
    AddToBlocksModal,
    Elements,
    ButtonLottieIcon,
    AddToFavoriteModal, BackgroundSliderV2, ShapeDivider, BackgroundVideo, Highliter
  },
  mixins: [SectionMixin, DraggableElementsHandler],

  data () {
    return {
      favoriteBlock: {},
      publicBlock: {}
    }
  },

  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    })
  },

  methods: {
    onAddToFavorite (node, section) {
      this.favoriteBlock = {
        section: JSON.parse(clonePart(section, node)),
        node
      }
    },
    addToBlocks () {
      this.publicBlock = {
        section: JSON.parse(clonePart(this.section, this.$el)),
        original: this.section
      }
    },
    addRow () {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {subject: this, originKey: 'children'}
        }
      })
    },
    favorite (payload) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: payload.favorite,
          group: 'rows'
        },
        inGlobal: payload.globally,
        node: payload.node
      })
    },
    onAddedRow ({newIndex}) {
      if (this.section.options.content.items.children[newIndex].name !== 'Flex Wrapper') return

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: this.section.options.content.items.children[newIndex].options.content.cols[0],
          options: {subject: this, originKey: 'elements'}
        }
      })
    },
    deleteSection (section) {
      this.section.options.content.items.children.splice(section, 1)
    },
    addSubject (index) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {index, originKey: 'children'}
        }
      })
    },
    duplicateElement (element) {
      this.section.options.content.items.children = insert(this.section.options.content.items.children, element, JSON.parse(JSON.stringify({
        ...this.section.options.content.items.children[element],
        uid: shortid.generate()
      })))
    },
    moveTo (element, to) {
      const copySection = JSON.parse(JSON.stringify(this.section.options.content.items.children[element]))

      if (to === 'up') {
        if (element === 0) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element - 1, copySection)
      } else if (to === 'down') {
        if (element + 1 === this.section.options.content.items.children.length) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element + 1, copySection)
      }
    }
  }
}
</script>

<style lang="scss">
.el-container {
  --width: var(--width);
  --min-height: auto;
  --height: 100%;
}

.el-block {
  --width: 70vw;
}

.block-container {
  & > div > .empty-row-drag {
    width: 100%;
    border-color: #82cec9;

    &:before {
      color: #82cec9;
    }
  }
}
</style>
