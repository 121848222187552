<template>
  <div>
    <SidbearSettingsGroup
      title="Control Center"
      active
    >
      <b-form-group label="Select a Video Source">
        <v-select
          :value="videoSource"
          label="name"
          :reduce="val => val.value"
          :searchable="false"
          :options="videoSourceOptions"
          :disabled="isLive"
          @input="selectVideoSource"
        />
      </b-form-group>

      <div v-if="videoSource === 'webcam'">
        <b-form-group label="Video Device">
          <v-select
            :value="selectedVideoDevice"
            label="name"
            :searchable="false"
            :options="videoDevices"
            :reduce="val => val.value"
            :disabled="screenShareEnabled"
            @input="switchVideoDevices"
          >
            <template #selected-option="{ name }">
              <div class="flex w-full items-center">
                <div class="w-4 h-4 rounded-full mr-2">
                  <i class="fa fa-camera" />
                </div>
                <div class="w-full truncate">
                  {{ name }}
                </div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <video
            ref="previewVideoElement"
            playsinline
            class="w-full aspect-video rounded-[4px] bg-[#E9E9E9]"
            muted
            autoplay
          />
        </b-form-group>

        <b-form-group>
          <button
            v-if="!screenShareEnabled"
            class="w-full bg-[rgba(0,0,0,0)] border border-[#D0D5DD] hover:bg-[#f3f4f6] flex items-center justify-center gap-2 px-[30px] h-[37px] text-[#344054] text-[13px] font-[Inter] font-[500] rounded"
            @click.stop="startScreenShare"
          >
            Screenshare
            <svg
              width="16"
              height="16"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4998 9.91667V11.3167C10.4998 11.6434 10.4998 11.8067 10.4363 11.9315C10.3803 12.0413 10.2911 12.1305 10.1813 12.1864C10.0565 12.25 9.8932 12.25 9.5665 12.25H4.43317C4.10647 12.25 3.94312 12.25 3.81834 12.1864C3.70858 12.1305 3.61934 12.0413 3.56342 11.9315C3.49984 11.8067 3.49984 11.6434 3.49984 11.3167V9.91667M3.9665 9.91667H10.0332C11.0133 9.91667 11.5033 9.91667 11.8777 9.72593C12.2069 9.55815 12.4747 9.29043 12.6424 8.96115C12.8332 8.5868 12.8332 8.09676 12.8332 7.11667V4.55C12.8332 3.56991 12.8332 3.07986 12.6424 2.70552C12.4747 2.37623 12.2069 2.10852 11.8777 1.94074C11.5033 1.75 11.0133 1.75 10.0332 1.75H3.9665C2.98641 1.75 2.49637 1.75 2.12202 1.94074C1.79274 2.10852 1.52502 2.37623 1.35724 2.70552C1.1665 3.07986 1.1665 3.56991 1.1665 4.55V7.11667C1.1665 8.09676 1.1665 8.5868 1.35724 8.96115C1.52502 9.29043 1.79274 9.55815 2.12202 9.72593C2.49637 9.91667 2.98641 9.91667 3.9665 9.91667Z"
                stroke="#344054"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <button
            v-else
            class="w-full bg-[rgba(0,0,0,0)] border border-[#D0D5DD] hover:bg-[#f3f4f6] flex items-center justify-center gap-2 px-[30px] h-[37px] text-[#344054] text-[13px] font-[Inter] font-[500] rounded"
            @click.stop="switchBackToCamera"
          >
            End Screenshare
            <svg
              width="16"
              height="16"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4998 9.91667V11.3167C10.4998 11.6434 10.4998 11.8067 10.4363 11.9315C10.3803 12.0413 10.2911 12.1305 10.1813 12.1864C10.0565 12.25 9.8932 12.25 9.5665 12.25H4.43317C4.10647 12.25 3.94312 12.25 3.81834 12.1864C3.70858 12.1305 3.61934 12.0413 3.56342 11.9315C3.49984 11.8067 3.49984 11.6434 3.49984 11.3167V9.91667M3.9665 9.91667H10.0332C11.0133 9.91667 11.5033 9.91667 11.8777 9.72593C12.2069 9.55815 12.4747 9.29043 12.6424 8.96115C12.8332 8.5868 12.8332 8.09676 12.8332 7.11667V4.55C12.8332 3.56991 12.8332 3.07986 12.6424 2.70552C12.4747 2.37623 12.2069 2.10852 11.8777 1.94074C11.5033 1.75 11.0133 1.75 10.0332 1.75H3.9665C2.98641 1.75 2.49637 1.75 2.12202 1.94074C1.79274 2.10852 1.52502 2.37623 1.35724 2.70552C1.1665 3.07986 1.1665 3.56991 1.1665 4.55V7.11667C1.1665 8.09676 1.1665 8.5868 1.35724 8.96115C1.52502 9.29043 1.79274 9.55815 2.12202 9.72593C2.49637 9.91667 2.98641 9.91667 3.9665 9.91667Z"
                stroke="#344054"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </b-form-group>

        <b-form-group label="Audio Device">
          <v-select
            :value="selectedAudioDevice"
            label="name"
            :searchable="false"
            :options="audioDevices"
            :reduce="val => val.value"
            @input="switchAudioDevices"
          >
            <template #selected-option="{ name }">
              <div class="flex w-full items-center">
                <div class="w-4 h-4 rounded-full mr-2">
                  <i class="fa fa-microphone" />
                </div>
                <div class="w-full truncate">
                  {{ name }}
                </div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="w-[128px] pb-0 mr-2">Input Level:</label>
            <volume-level
              v-if="selectedAudioDevice"
              :device-id="selectedAudioDevice"
            />
          </div>
        </b-form-group>
      </div>

      <div v-if="videoSource === 'rtmp'">
        <!--        <b-form-group>-->
        <!--          <div class="relative">-->
        <!--            <div-->
        <!--              ref="previewContainer"-->
        <!--              class="w-full aspect-video rounded-[4px] bg-[#E9E9E9]" -->
        <!--            />-->
        <!--          </div>-->
        <!--        </b-form-group>-->
        
        <b-form-group label="Stream Link">
          <b-input-group>
            <b-input
              :value="streamData.link"
              style="height: 100% !important;"
              readonly
            />
            <b-input-group-append>
              <b-btn
                v-b-tooltip.bottom="tooltipText"
                v-clipboard:copy="streamData.link"
                v-clipboard:success="onCopy"
                variant="primary"
                class="px-2 py-1"
              >
                <i class="fa fa-clipboard" />
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Stream Key">
          <b-spinner
            v-if="!section.options.content.liveStream.rtmp_server_stream_key || !token"
            variant="dark"
            small
          />
          <b-input-group v-if="section.options.content.liveStream.rtmp_server_stream_key && token">
            <b-input
              :value="streamKey"
              style="height: 100% !important;"
              type="password"
              readonly
            />
            <b-input-group-append>
              <b-btn
                v-b-tooltip.bottom="tooltipText"
                v-clipboard:copy="streamKey"
                v-clipboard:success="onCopy"
                variant="primary"
                class="px-2 py-1"
              >
                <i class="fa fa-clipboard" />
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <div v-if="videoSource === 'srt'">
        <b-form-group label="Stream Link">
          <b-input-group>
            <b-input
              :value="srtServer"
              style="height: 100% !important;"
              type="password"
              readonly
            />
            <b-input-group-append>
              <b-btn
                v-b-tooltip.bottom="tooltipText"
                v-clipboard:copy="srtServer"
                v-clipboard:success="onCopy"
                variant="primary"
                class="px-2 py-1"
              >
                <i class="fa fa-clipboard" />
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      
      <b-form-group>
        <button
          v-if="!isLive"
          class="w-full bg-[#3D82EA] border border-[#3D82EA] hover:bg-[#60a5fa] flex items-center justify-center gap-2 px-[30px] h-[37px] text-white text-[13px] font-[Inter] font-[500] rounded"
          :class="{'opacity-50': disableStream && !key && !rtmp_server_stream_key}"
          :disabled="disableStream && !key && !rtmp_server_stream_key"
          @click.stop="goLive"
        >
          {{ disableStream ? 'Live Video is ending...' : 'Go Live' }}
        </button>

        <button
          v-else
          class="w-full bg-[#ef4444] border border-[#ef4444] hover:bg-[#f87171] flex items-center justify-center gap-2 px-[30px] h-[37px] text-white text-[13px] font-[Inter] font-[500] rounded"
          @click.stop="goOffline"
        >
          End Live Video
        </button>
      </b-form-group>
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Video Settings">
      <b-form-group label="Placeholder Image (Offline)">
        <image-upload
          :image="preview"
          class="w-100"
          @imageDeleted="deleteImage"
          @uploaded="uploadedImage"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-end">
          <b-checkbox
            v-model="section.options.content.liveStream.showMembersCount"
            size="lg"
            switch
          />
          <label class="mb-0 ml-1 pb-0">Show Members Count</label>
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--element`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--element`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--element`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--element`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <sid-box-shadow
        :sid="`.--${section.sid}--element`"
        rule-name="box-shadow"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import {db} from '@/firebase.js'
import {useWebRTC} from '@/composition/useWebRTC'
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ImageUpload from '@/components/editor/utils/ImageUpload.vue'
import VolumeLevel from '@/components/builder/utils/VolumeLevel.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'

export default {
  name: 'LiveStreamMod',
  components: {
    SidSelect, SidColor,
    SidBoxShadow,
    SidLinkedGroup,
    VolumeLevel,
    ImageUpload,
    SidbearSettingsGroup
  },
  mixins: [ModMixin],
  data () {
    return {
      preview: {
        src: this.poster
      },
      videoSourceOptions: [
        {
          name: 'Webcam',
          value: 'webcam'
        },
        {
          name: 'RTMP',
          value: 'rtmp'
        },
        {
          name: 'SRT',
          value: 'srt'
        }
      ],
      tooltipText: 'Copy',
      streamData: {
        link: 'rtmp://104.248.5.168/WebRTCAppEE',
        token: null,
        key: null
      },
      screenShareEnabled: false,
      selectedVideoDevice: '',
      selectedAudioDevice: '',
      audioDevices: [],
      videoDevices: [],
      stream: null,
      streamLive: false,
      previewLoading: false,
      disableStream: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('liveStream', {
      rtmp_server_stream_key: state => state.rtmp_server_stream_key,
      token: state => state.token,
      isLive: state => state.isLive,
      poster: state => state.poster
    }),
    selectedDevices () {
      return [this.selectedVideoDevice, this.selectedAudioDevice]
    },
    videoSource: {
      get () {
        return this.section.options.content.liveStream.videoSource || 'rtmp'
      },
      set (val) {
        this.$set(this.section.options.content.liveStream, 'videoSource', val)
      }
    },
    key () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!this.section.options.content.liveStream.rtmp_server_stream_key) this.section.options.content.liveStream.rtmp_server_stream_key = this.rtmp_server_stream_key
      return this.section.options.content.liveStream.rtmp_server_stream_key
    },
    streamKey () {
      return `stream-${this.key}_p-${this.project.id}_t-page`
    },
    srtServer () {
      return `srt://104.248.5.168:4200?streamid=WebRTCAppEE/${this.streamKey},token=${this.token}`
    },
    webRTC () {
      const {hangup, call, replaceTrack, replaceVideoTrack, replaceAudioTrack, rating} = useWebRTC(this.token, this.key, this.project.id, 'page')
      return {hangup, call, replaceTrack, replaceVideoTrack, replaceAudioTrack, rating}
    }
  },
  methods: {
    ...mapActions({
      getLiveStreamToken: 'liveStream/getLiveStreamToken',
      postLiveStream: 'liveStream/postLiveStream',
      putLiveStream: 'liveStream/putLiveStream',
      putLiveStreamPreview: 'liveStream/putLiveStreamPreview'
    }),
    ...mapMutations({
      setPoster: 'liveStream/SET_POSTER',
      setLoading: 'liveStream/SET_LOADING',
      setLive: 'liveStream/SET_LIVE',
      setRtmpServerStreamKey: 'liveStream/SET_RTMP_SERVER_STREAM_KEY'
    }),
    onCopy () {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    },
    selectVideoSource (val) {
      this.videoSource = val
      if (val === 'webcam') {
        this.startCamera()
      }
    },
    async goLive () {
      this.setLoading(true)
      if (this.videoSource === 'webcam') {
        await this.webRTC.call(this.stream, 2500)
      }
      await this.putLiveStream()
      this.setLive(true)
      this.setLoading(false)
    },
    async goOffline () {
      if (this.videoSource === 'webcam') {
        await this.webRTC.hangup()
      }
      await this.putLiveStream()
      this.setLive(false)
      this.disableStream = true
      setTimeout(() => {
        this.disableStream = false
      }, 10000)
    },
    async loadDevices () {
      const devices = await navigator.mediaDevices.enumerateDevices()
      this.videoDevices = devices.filter(device => device.kind === 'videoinput').map(({label, deviceId}) => {
        return {name: label, value: deviceId}
      })
      this.audioDevices = devices.filter(device => device.kind === 'audioinput').map(({label, deviceId}) => {
        return {name: label, value: deviceId}
      })

      if (this.videoDevices.length > 0) this.selectedVideoDevice = this.videoDevices[0].value
      if (this.audioDevices.length > 0) this.selectedAudioDevice = this.audioDevices[0].value
    },
    async stopDevices () {
      if (this.stream) {
        await this.stream.getTracks().forEach(track => track.stop())
      }
    },
    async loadPreview () {
      // const signalingUrl = `https://estagestream.com:1990/rtc/v1/whep/?app=live&stream=${this.key}`
      // this.previewLoading = true
      let videoPlayerPreview = null
      const streamId = `stream-${this.key}_p-${this.project.id}_t-page`
      const WebPlayer = (await import('@antmedia/web_player')).WebPlayer
      const sdk = new WebPlayer({
        streamId: this.key,
        httpBaseURL: 'https://estage.live:5443/WebRTCAppEE',
        playOrder: ['webrtc'],
        mute: true,
        videoHTMLContent: '<video id="video-player" class="video-js h-full w-full" muted controls playsinline></video>'
      }, this.$refs.previewContainer, null)

      function updateResolution (stats) {
        if (stats && stats.inboundRtpList) {
          const videoStats = stats.inboundRtpList.find(track => track.trackIdentifier.includes('videoTrack'))

          if (videoStats) {
            const videoResolution = `${videoStats.frameWidth}x${videoStats.frameHeight}`
            this.autoQuality = videoStats.frameHeight
            console.log(`Resolution: ${videoResolution}`)
          }
        }
      }

      sdk.initialize().then(() => {
        sdk.play()
        sdk.videojsPlayer.on('play', () => {
          this.ready.value = true
        })

        sdk.videojsPlayer.on('ready', function () {
          videoPlayerPreview = sdk.videojsPlayer.el_.querySelector('video')
          setTimeout(async () => {
            this.resolutions = [
              {
                value: 0,
                label: 'Auto'
              },
              ...sdk.videojsPlayer.controlBar.player_.resolutions
            ]

            videoPlayerPreview.addEventListener('webkitendfullscreen', function () {
              setTimeout(() => {
                this.playing.value = false
              }, 100)
            }, false)

            sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.enableStats(streamId)
            await sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.getStats(streamId)

            sdk.videojsPlayer.on('timeupdate', () => {
              const stats = sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.remotePeerConnectionStats[streamId]
              updateResolution(stats)
            })
          }, 3000)
        })
      }).catch((error) => {
        console.error('Error initializing player: ' + error)
      })

      // const previewInstance = new WebRTCPlayer({
      //   video: this.$refs.previewVideoElement,
      //   type: 'whep',
      //   statsTypeFilter: '^candidate-*|^inbound-rtp'
      // })

      // await previewInstance.load(new URL(signalingUrl))
      // await this.$refs.previewVideoElement.play()
      //   .then(() => {
      //     this.previewLoading = false
      //   })
      //   .catch(() => {
      //     this.previewLoading = false
      //   })
    },
    async unloadPreview () {
      this.$refs.previewVideoElement.srcObject = null
    },
    async startCamera () {
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.selectedVideoDevice,
          height: { ideal: 1080, max: 2160, min: 720 },
          width: { ideal: 1920, max: 3840, min: 1280 }
        },
        audio: {
          deviceId: this.selectedAudioDevice
        }
      })

      // const newVideoTrack = this.stream.getVideoTracks()[0]
      // await this.webRTC.replaceVideoTrack(newVideoTrack)
      // const newAudioTrack = this.stream.getAudioTracks()[0]
      // await this.webRTC.replaceAudioTrack(newAudioTrack)
      //
      // this.stream = new MediaStream([newVideoTrack, newAudioTrack])
      this.$refs.previewVideoElement.srcObject = this.stream
    },
    async startScreenShare () {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            height: { ideal: 1080, max: 1080 },
            width: { ideal: 1920, max: 1920 },
            displaySurface: 'monitor'
          },
          audio: false // Optional: Add audio if you want to share system audio
        })

        console.log('Stream:', this.stream.getVideoTracks())
        console.log('screenStream:', screenStream)

        // Get the display surface type (monitor, window, application, browser)
        const screenTrack = screenStream.getVideoTracks()[0]
        await this.webRTC.replaceVideoTrack(screenTrack)

        // Stop the original video tracks to only show the screen
        this.stream.getVideoTracks().forEach(track => track.stop())

        // Combine the new screen track with the existing audio track
        const audioTrack = this.stream.getAudioTracks()[0]
        this.stream = new MediaStream([screenTrack, audioTrack])

        this.$refs.previewVideoElement.srcObject = this.stream
        this.screenShareEnabled = true

        screenTrack.onended = () => {
          if (this.screenShareEnabled) {
            this.switchBackToCamera()
          }
        }
      } catch (error) {
        console.error('Error starting screen share:', error)
        alert('Unable to start screen share. Please check permissions.')
      }
    },
    async switchVideoDevices (deviceId) {
      this.selectedVideoDevice = deviceId
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: deviceId,
          width: { ideal: 1920, max: 3840, min: 1280 },
          height: { ideal: 1080, max: 2160, min: 720 }
        },
        audio: true
      })

      const newVideoTrack = newStream.getVideoTracks()[0]
      await this.webRTC.replaceVideoTrack(newVideoTrack)

      this.stream.getVideoTracks().forEach(track => track.stop())
      const audioTrack = this.stream.getAudioTracks()[0]
      this.stream = new MediaStream([newVideoTrack, audioTrack])
      this.$refs.previewVideoElement.srcObject = this.stream
      this.screenShareEnabled = false
    },
    async switchAudioDevices (deviceId) {
      this.selectedAudioDevice = deviceId
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          deviceId: deviceId
        }
      })

      const newAudioTrack = newStream.getAudioTracks()[0]
      await this.webRTC.replaceAudioTrack(newAudioTrack)

      this.stream.getAudioTracks().forEach(track => track.stop())
      const videoTrack = this.stream.getVideoTracks()[0]
      this.stream = new MediaStream([videoTrack, newAudioTrack])
      this.$refs.previewVideoElement.srcObject = this.stream
    },
    async switchBackToCamera () {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.selectedVideoDevice,
          width: { ideal: 1920, max: 3840, min: 1280 },
          height: { ideal: 1080, max: 2160, min: 720 }
        },
        audio: {
          deviceId: this.selectedAudioDevice
        }
      })

      const newVideoTrack = newStream.getVideoTracks()[0]
      await this.webRTC.replaceVideoTrack(newVideoTrack)

      console.log('Stream:', this.stream)
      this.stream.getVideoTracks().forEach(track => track.stop())
      const audioTrack = this.stream.getAudioTracks()[0]
      this.stream = new MediaStream([newVideoTrack, audioTrack])
      this.$refs.previewVideoElement.srcObject = this.stream
      this.screenShareEnabled = false
    },
    async uploadedImage (image) {
      await this.putLiveStreamPreview(image.src)
    },
    async deleteImage () {
      await this.putLiveStreamPreview('')
    },
    async getToken () {
      await this.getLiveStreamToken()
    }
  },
  watch: {
    async key (val) {
      if (val) {
        await this.getToken()
      }
    }
    // async streamLive (val) {
    //   if (val && this.videoSource === 'rtmp') {
    //     await this.loadPreview()
    //   } else if (!val && this.videoSource === 'rtmp') {
    //     await this.unloadPreview()
    //   }
    // }
  },
  async created () {
    await this.loadDevices()
    if (this.key) {
      await this.getToken()
    }
  },
  async mounted () {
    if (!this.unsubscribeMessages && this.key) {
      const streamDoc = doc(collection(db, 'lives'), this.key)
      this.unsubscribeMessages = onSnapshot(streamDoc, snapshot => {
        this.streamLive = snapshot.data().streamLive
      })
    }
    this.setRtmpServerStreamKey(this.section.options.content.liveStream.rtmp_server_stream_key)
    if (this.videoSource === 'webcam') {
      await this.startCamera()
    }
  },
  async beforeDestroy () {
    if (this.unsubscribeMessages) this.unsubscribeMessages()
    if (!this.isLive) {
      await this.stopDevices()
      // await this.webRTC.hangup()
    }
  }
}
</script>

<style>
.vs__selected-options {
  width: 100%;
}

.webcam-preview {
  width: 100%;
  height: 128px;
  border-radius: 4px;
  background: #000;
}
</style>