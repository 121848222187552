<template>
  <div
    class="relative w-screen h-screen p-[24px] flex flex-col justify-center items-center overflow-clip"
    style="background: linear-gradient(to bottom right, #000000 29.56%, #252C32 100%);"
  >
    <div
      class="maintenance-logo absolute bg-cover aspect-square bg-no-repeat"
    />
    <p class="text-white text-[24px] md:text-[32px] leading-tight tracking-normal font-roboto font-semibold text-center m-0">
      Undergoing scheduled system maintenance.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Maintenance'
}
</script>

<style lang="css">
.maintenance-logo {
  background-image: url('../../assets/svg/logo-decoration.svg');
  height: 100%;
  right: 20%;
  top: -45%;
  transform: translateX(50%) translateY(50%);
}
</style>