<template>
  <div
    v-if="showPaymentProvider"
    :data-sid="section.sid"
    :class="`--${section.sid}--wrapper`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="w-full !mb-[0]"
        :class="`--${section.sid}--container`"
      >
        <div
          class="flex justify-between items-center gap-[12px]"
          :class="`--${section.sid}--header`"
        >
          <p :class="`--${section.sid}--header-name`">
            {{ section.options.content.paymentProvider.label }}
          </p>
        </div>
        
        <b-form-radio-group
          v-model="provider"
          class="flex flex-col"
          :class="`--${section.sid}--providers`"
        >
          <template #default>
            <div
              v-for="(item, index) in providers"
              :key="index"
              :class="[`--${section.sid}--provider`, {'active': provider === item.code}]"
            >
              <b-form-radio
                :value="item.code"
                class="w-full flex items-center justify-between gap-[12px]"
                @change="setSelectedProvider(item.code)"
              >
                <span :class="[`--${section.sid}--provider-item`, {'active': provider === item.code}]">
                  {{ section.options.content.paymentProvider.providers ? section.options.content.paymentProvider.providers[index].name : item.name }}
                </span>
              </b-form-radio>
            </div>
          </template>
        </b-form-radio-group>
      </b-form-group>
    </highliter>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'

export default {
  name: 'ElPaymentProvider',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    ...mapState('products', {
      selectedProduct: state => state.selectedProduct
    }),
    ...mapState('orderForm', {
      selectedProvider: (state) => state.selectedProvider,
      providers: (state) => state.providers
    }),
    provider: {
      get () {
        return this.$store.state.orderForm.selectedProvider
      },
      set (value) {
        this.setProvider(value)
      }
    },
    showPaymentProvider () {
      return this.providers.length >= 2 && !this.selectedProduct.type_plan
    }
  },
  methods: {
    ...mapActions({
      setProvider: 'orderForm/setProvider'
    }),
    setSelectedProvider (provider) {
      this.provider = provider || null
    },
    setDefaultProvider () {
      if (Array.isArray(this.providers) && this.providers.length > 0) {
        this.provider = this.providers[0].code
      }
    }
  },
  watch: {
    providers (val) {
      if (val) {
        this.setDefaultProvider()
      }
    },
    selectedProduct (val) {
      if (val) {
        if (!this.section.options?.content?.paymentProvider) {
          this.$set(this.section.options.content, 'paymentProvider', { providers: [] })
        }

        if (!Array.isArray(this.section.options.content.paymentProvider.providers)) {
          this.$set(this.section.options.content.paymentProvider, 'providers', [])
        }

        if (Array.isArray(this.providers)) {
          this.providers.forEach(provider => {
            const exists = this.section.options.content.paymentProvider.providers.some(option => option.code === provider.code)
            if (!exists) {
              this.section.options.content.paymentProvider.providers.push({
                name: provider.name,
                code: provider.code
              })
            }
          })
        }

        this.setDefaultProvider()
      }
    }
  },
  mounted () {
    if (!this.section.options.content.paymentProvider.providers) {
      this.section.options.content.paymentProvider.providers = []
    }

    if (Array.isArray(this.providers) && this.providers.length) {
      this.providers.forEach(provider => {
        const exists = this.section.options.content.paymentProvider.providers.some(option => option.code === provider.code)
        if (!exists) {
          this.section.options.content.paymentProvider.providers.push({
            name: provider.name,
            code: provider.code
          })
        }
      })

      this.setDefaultProvider()
    }
  }
}
</script>
