<template>
  <b-form-group
    class="estage-form-element-group mb-0"
  >
    <b-checkbox
      v-model="control.value"
      :required="!!control.is_required"
      class="w-full"
    >
      <legend
        class="mb-2 mt-[-2px] text-wrap break-words"
        v-text="control.label"
      />
      <p
        v-if="control.placeholder"
        class="font-normal secondary-text text-wrap break-words"
        v-text="control.placeholder"
      />
    </b-checkbox>
  </b-form-group>
</template>

<script>
import FormControlMixin from './FormControlMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FormInput',
  mixins: [FormControlMixin, BorderMixin]
}
</script>

<style>
fieldset legend {
  transition: 150ms;
}

.custom-control-label {
  width: 100%;
}
</style>